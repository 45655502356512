import React from 'react';
import { Component } from 'react';
import './PersonDetailScreen.css';
import Button from '../../../components/common/Button';
import Loader from '../../../components/common/Loader';
import { I18n } from 'react-redux-i18n';
//import Input from '../../../components/common/Input';
//import DatePicker from '../../../components/common/DatePicker';
//import SquareButton from '../../../components/common/SquareButton';
import InputAutocomplete from '../../../components/common/InputAutocomplete';
import {
  parseBackDate,
  parseDate,
  parseLargeText,
} from '../../../utils/parser';
import { notify } from 'react-notify-toast';
import { error } from '../../../utils/modals';
import { NumberLabel } from '../../../components/common/NumberLabel/NumberLabel';
import { Tabs, Tab } from 'react-bootstrap';
import NewIndicator from '../../../components/common/NewIndicator/NewIndicator';
import EmailReportConfirmModal from '../EmailReportConfirmModal/EmailReportConfirmModal';
import DownloadReportModal from '../../../components/common/DownloadReportModal/DownloadReportModal';
import DeleteModal from '../../../components/common/ModalAlert/ModalAlert';
import dayjs from 'dayjs';
import {
  errorMessage,
  SnackBar,
  successMessage,
} from '../../../utils/snackbar';
import UploadFiles from '../../../components/common/UploadFiles/UploadFiles';
import ModalShare from '../ModalShare';
import LargeTextTooltip from '../../../components/common/LargeTextTooltip';
import { Tooltip } from 'react-tooltip';
import PersonalData from './PersonalData';
import IePersonCard from '../IePersonCard/IePersonCard';
class PersonDetailScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        country: this.props.countries?.map((country) => ({
          code: country.id,
          description: country.name,
        })),
        nationality: this.props.countries?.map((country) => ({
          code: country.id,
          description: country.name,
        })),
        city: this.props.cities.map((city) => ({
          code: city.id,
          description: city.name,
        })),
      },
      noteClicked: {
        id: null,
        index: null,
      },
      heightCard: 500,
      tabs: 10,
      prevNotes: [],
      showTimeLine: false,
      editing: undefined,
      showEmailReportConfirm: false,
      showDeleteModal: false,
      showShareModal: false,
      downloadReportModalShow: false,
      showDeleteCVModal: false,
      selectedTab: 1,
      profileLoading: false,
      values: {
        codeEmail: { inputValue: '' },
        editingEmail: { inputValue: '' },
        first_name: { inputValue: '' },
        last_name: { inputValue: '' },
        email: { inputValue: '' },
        nationality: { inputValue: '' },
        country: { inputValue: '' },
        city: { inputValue: '' },
        note: { inputValue: '' },
        noteToDelete: {},
      },
      submit: false,
      code: {},
    };
  }

  loadValues() {
    let newValues = {};
    Object.keys(this.props.person).forEach((key) => {
      if (key === 'birthday') {
        newValues = {
          ...newValues,
          [key]: { inputValue: new Date(this.props.person[key]) },
        };
      } else if (key === 'city_info') {
        newValues = {
          ...newValues,
          city: {
            inputValue: {
              code: this.props.person[key].id,
              description: this.props.person[key].name,
            },
          },
          country: {
            inputValue: {
              code: this.props.person[key]['country_info'].id,
              description: this.props.person[key]['country_info'].name,
            },
          },
        };
      } else if (key === 'user') {
        ['first_name', 'last_name', 'email'].forEach((key) => {
          newValues = {
            ...newValues,
            [key]: { inputValue: this.props.person.user[key] },
          };
        });
      } else {
        newValues = {
          ...newValues,
          [key]: { inputValue: this.props.person[key] },
        };
      }
    });
    this.setState({
      ...this.state,
      values: { ...this.state.values, ...newValues },
    });
  }

  handleSelect = (tab) => {
    this.setState({
      ...this.state,
      selectedTab: tab,
    });
  };

  handleHeights(unmount = false) {
    if (!unmount) {
      this.setState({
        ...this.state,
        heightCard: document.getElementById('uncontrolled-tab-example')
          ?.children[1]?.offsetHeight,
      });
    }
  }

  handleCopy = () => {
    let email =
      this.props.person.user_player.email ||
      this.props.person.user_player.user.email;
    let success = false;
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(email);
      success = true;
    } else {
      const textArea = document.createElement('textarea');
      textArea.style.position = 'fixed';
      textArea.value = email;
      textArea.style.opacity = '0';
      textArea.style.height = '0';
      textArea.style.width = '0';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      textArea.remove();
      success = true;
    }
    if (success === true)
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('persons.email_copy')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
  };

  componentDidMount() {
    window.addEventListener('load', () => this.handleHeights());
    window.addEventListener('resize', () => this.handleHeights());
    if (this.props.person.code !== this.props.match.params.id)
      this.props.getPerson(this.props.match.params.id);

    this.setState({ currentProfile: this.props.person.profile_name });
    this.loadValues();
    this.props.getCountries();
    this.props.getProfile(
      this.props.person.result.nwm_result_distance_match.profile.id,
    );
    this.props.getCampaigns(this.props.person.campaign.id);
    this.props.getUserPlayerInformation(this.props.person.id);
    this.props.getNotes(this.props.person.user_player.id);
    this.props.getCV(this.props.person.user_player.id);
    this.handleHeights();
  }

  componentWillUnmount() {
    this.props.clearCV();
    window.removeEventListener('load', () => this.handleHeights(true));
    window.removeEventListener('resize', () => this.handleHeights(true));
  }

  componentDidUpdate(prevProps) {
    if (this.props.countries?.length !== prevProps.countries?.length) {
      this.setState({
        ...this.state,
        options: {
          ...this.state.options,
          country: this.props.countries?.map((country) => ({
            code: country.id,
            description: country.name,
          })),
          nationality: this.props.countries?.map((country) => ({
            code: country.id,
            description: country.name,
          })),
        },
      });
    }
    if (
      prevProps.person !== this.props.person ||
      prevProps.userPlayerInformation !== this.props.userPlayerInformation ||
      prevProps.currentProfile !== this.props.currentProfile
    ) {
      this.handleHeights();
    }
  }

  handleProfileLoading = (bool) => {
    this.setState({
      ...this.state,

      profileLoading: bool,
    });
  };

  sendIeInvitation = () => {
    this.props.addIeInvitation({
      id_invitation: this.props.person.id,
      email:
        this.props.person.user_player.email ||
        this.props.person.user_player.user.email,
      id_campaign: this.props.person.campaign.id,
      lang: this.props.language,
    });
  };

  handleInputChange = (name, value, getOpts) => {
    if (getOpts) {
      let newOptions = {};
      if (name === 'country' || name === 'nationality') {
        if (value.code && name === 'country') {
          this.props.getCities(value.code);
        }
        newOptions = this.props.countries
          .filter((opt) =>
            opt.name.toLowerCase().startsWith(value.description?.toLowerCase()),
          )
          .map((opt) => ({ code: opt.id, description: opt.name }));
      } else if (name === 'city') {
        newOptions = this.props.cities
          .filter((opt) =>
            opt.name.toLowerCase().startsWith(value.description?.toLowerCase()),
          )
          .map((opt) => ({ code: opt.id, description: opt.name }));
      }
      this.setState({
        ...this.state,
        values: {
          ...this.state.values,
          [name]: { ...this.state.values[name], inputValue: value },
          city:
            name === 'country'
              ? { inputValue: { code: '', description: '' } }
              : name === 'city'
              ? { inputValue: value }
              : { ...this.state.values.city },
        },
        options: {
          ...this.state.options,
          [name]: newOptions,
        },
      });
    } else {
      this.setState({
        ...this.state,
        values: {
          ...this.state.values,
          [name]: { ...this.state.values[name], inputValue: value },
          city:
            name === 'country'
              ? { inputValue: { code: '', description: '' } }
              : name === 'city'
              ? { inputValue: value }
              : { ...this.state.values.city },
        },
      });
    }
  };

  handlePersonUpdate = () => {
    this.setState({ ...this.state, editing: false });
    let submitForm = {};
    let userData = {};
    let fields = [
      'birthday',
      'gender_type',
      'doc_type',
      'dni',
      'address',
      'city',
      'phone',
      'max_studies_level_achieved',
      'studies_finalized',
      'studies_area',
      'job_experience',
      'job_type',
      'looking_for_job',
    ];

    Object.keys(this.state.values).forEach((value) => {
      if (
        value === 'last_name' ||
        value === 'first_name' ||
        value === 'email'
      ) {
        userData = {
          ...userData,
          [value]:
            this.state.values[value].inputValue &&
            this.state.values[value].inputValue.code
              ? this.state.values[value].inputValue.code
              : this.state.values[value].inputValue,
        };
      } else if (fields.indexOf(value) >= 0) {
        if (value === 'birthday') {
          submitForm = {
            ...submitForm,
            [value]: parseBackDate(this.state.values[value].inputValue),
          };
        } else {
          submitForm = {
            ...submitForm,
            [value]:
              this.state.values[value].inputValue &&
              this.state.values[value].inputValue.code
                ? this.state.values[value].inputValue.code
                : this.state.values[value].inputValue,
          };
        }
      }
    });

    this.props.updatePerson(
      this.props.person.id,
      submitForm,
      this.props.person.user.id,
      userData,
    );
  };

  onHide = () => {
    this.setState({
      ...this.state,
      showEmailReportConfirm: false,
      showDeleteModal: false,
      showShareModal: false,
    });
  };

  onHideCV = () => {
    this.setState({
      ...this.state,
      showDeleteCVModal: false,
    });
  };

  handleCellEmailReportConfirm = () => {
    this.props.sendPersonReportEmail({
      value: this.props.person.result.id,
      id_invitation: this.props.person.id,
      campaign: this.props.person.campaign.name,
    });
  };

  modalShow = () => {
    if (this.state.downloadReportModalShow === false) {
      this.setState({
        ...this.state,
        showEmailReportConfirm: false,
        downloadReportModalShow: true,
      });
    } else {
      this.setState({
        ...this.state,
        downloadReportModalShow: false,
      });
    }
  };

  handleActions = (name, option) => {
    if (option.code === 'tends') {
      this.props.history.push('/main/persons/group-trends');
    }

    if (option.code === 'individual' || option.code === 'empresa') {
      this.props.downloadProfileReport(
        [
          {
            id_result: this.props.person.result.id,
            id_invitation: this.props.person.id,
          },
        ],
        option.code,
      );
    }
  };

  render() {
    if (this.props.updateStatus.error || this.props.updateUserStatus.error) {
      notify.show(I18n.t('persons.error.update'), 'custom', 5000, error);
      this.props.clearUiValue('update', 'error');
      this.props.clearUiValue('userUpdate', 'error');
    }
    if (this.props.deleteNoteStatus.success) {
      notify.show(
        <SnackBar
          icon={'report'}
          title={I18n.t('persons.note-modal.title')}
          body={I18n.t('persons.note-modal.description')}
          type="error"
          iconFilled={true}
        />,
        'custom',
        5000,
        errorMessage,
      );
      this.props.clearUiValue('deleteNoteStatus', 'success');
    }

    if (this.props.sendPersonReportEmailStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('persons.excellent')}
          body={`${I18n.t('persons.reportResended')} ${
            this.props.person.user_player.user.first_name +
            ' ' +
            this.props.person.user_player.user.last_name
          }`}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('sendPersonReportEmailStatus', 'success');
    }
    if (this.props.shareFileStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('persons.excellent')}
          body={I18n.t('persons.shareSuccess')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('shareFileStatus', 'success');
    }
    return (
      <div className="person-detail-container row">
        <EmailReportConfirmModal
          show={this.state.showEmailReportConfirm}
          onHide={this.onHide}
          onConfirm={this.handleCellEmailReportConfirm}
          name={
            this.props.person.user_player?.user?.first_name +
            ' ' +
            this.props.person.user_player?.user?.last_name
          }
        />
        <DeleteModal
          show={this.state.showDeleteModal}
          onHide={this.onHide}
          actionCancel={this.onHide}
          actionConfirm={() =>
            this.props.deleteNote({
              note_id: this.state.values.noteToDelete.id,
              player_id: this.props.person.user_player?.id,
            })
          }
          name="persons"
          message={I18n.t('persons.modal.message', {
            day: dayjs(this.state.values.noteToDelete?.created).format('DD/MM'),
            hour: dayjs(this.state.values.noteToDelete?.created).format(
              'HH:mm',
            ),
          })}
        />

        {this.state.showDeleteCVModal && (
          <DeleteModal
            show={this.state.showDeleteCVModal}
            onHide={this.onHideCV}
            actionCancel={this.onHideCV}
            actionConfirm={this.deleteAttachedFile}
            name="persons"
            message={I18n.t('persons.CV.deleteMessage')}
          />
        )}

        <ModalShare
          show={this.state.showShareModal}
          onHide={this.onHide}
          action={(body) =>
            this.props.shareFile({
              id_invitation: this.props.person.id,
              ...body,
            })
          }
        />

        {this.state.downloadReportModalShow && (
          <DownloadReportModal
            show={this.state.downloadReportModalShow}
            action={this.handleActions}
            onHide={this.modalShow}
          />
        )}
        <div className={`buttons-container`}>
          <Button
            className="primary"
            text={I18n.t('persons.shareFile')}
            iconFilled={true}
            iconClass="share"
            iconButton
            onClick={() =>
              this.setState({ ...this.state, showShareModal: true })
            }
          />
        </div>
        <div
          className={`${
            this.state.selectedTab === 1 ? 'col-md-5' : ' '
          } first-col`}
          id="tabs-codes"
        >
          <Tabs
            defaultActiveKey={this.props.defaultTab || 1}
            id="uncontrolled-tab-example"
            onSelect={this.handleSelect}
            value={this.state.selectedTab}
          >
            <Tab eventKey={1} title={I18n.t('persons.identifierPersons')}>
              {this.renderCard()}
            </Tab>
            <Tab
              eventKey={2}
              title={
                <div className="tab-notes-indicator">
                  <NewIndicator />
                  {I18n.t('persons.note')}
                </div>
              }
            >
              {this.renderNotes()}
            </Tab>
          </Tabs>
        </div>
        {this.state.selectedTab === 1 && (
          <div className="second-col col-md-7">
            <div className="card card-container">
              <div className="profile-description">
                <div className="header-profile-description">
                  <div className="profile-indicator-container">
                    <span className="material-symbols-filled icon">
                      psychology
                    </span>
                    <p className="profile-indicator">
                      {I18n.t('persons.profileResult')}
                    </p>
                    <h3>{this.props.currentProfile.name}</h3>
                  </div>
                  <div className="buttons">
                    {this.props.currentProfile.id !== 27 && (
                      <>
                        <Button
                          className="secondary"
                          text={I18n.t('persons.resendReport')}
                          iconClass="reply"
                          iconButton
                          iconFilled={true}
                          onClick={() =>
                            this.setState({
                              ...this.state,
                              showEmailReportConfirm: true,
                            })
                          }
                        />
                        <Button
                          className="secondary"
                          text={I18n.t('persons.downloadReport')}
                          iconClass="download"
                          iconButton
                          onClick={this.modalShow}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="tags-list">
                  {this.props.currentProfile.nwm_profile_x_tag
                    ?.slice(0, 5)
                    ?.map((item, index) => {
                      return (
                        <div
                          className="tag-container"
                          onMouseEnter={() =>
                            (window.document.getElementById(
                              item.tag[`name_${this.props.locale}`],
                            ).className = 'div-active card')
                          }
                          onMouseLeave={() =>
                            (window.document.getElementById(
                              item.tag[`name_${this.props.locale}`],
                            ).className = 'div-inactive')
                          }
                        >
                          <span className="tag" key={index}>
                            {item.tag[`name_${this.props.locale}`]}
                          </span>
                          <div
                            className="div-inactive"
                            id={item.tag[`name_${this.props.locale}`]}
                          >
                            <span className="description-inactive">
                              {item.tag[`description_${this.props.locale}`]}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className="tag-description">
                  <p>{this.props.currentProfile.description}</p>
                </div>
              </div>
            </div>

            {process.env.REACT_APP_ENABLED_IA_GAME === 'true' &&
              (this.props.user.branch?.account?.ie_active === true ||
                this.props.user.account?.ie_active === true ||
                this.props.user.user_admin) && (
                <div className="container-ie">
                  <div className="card card-container">
                    <IePersonCard
                      hasInvitation={
                        this.props.userPlayerInformation?.ie_invitation
                      }
                      personDetails={this.props.person}
                      result={
                        this.props.userPlayerInformation?.ie_invitation?.result
                      }
                      disabled={
                        this.props.currentProfile.id === 27 ||
                        this.props.user.user_admin
                      }
                      onClick={this.sendIeInvitation}
                      lang={this.props.language}
                      playerName={this.props.person.user_player.user.first_name}
                      handleProfileLoading={this.handleProfileLoading}
                    />
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    );
  }

  handleChangeCV(file) {
    if (file?.length) {
      this.props.addCV({
        id_user_player: this.props.person.user_player.id,
        curriculum: file,
      });
    }
  }

  handleDeleteAttached = (deletedFile) => {
    this.setState({
      showDeleteCVModal: true,
      deletedFile: deletedFile,
    });
  };

  deleteAttachedFile = () => {
    this.props.deleteAttached({
      id_user_player: this.props.person.user_player.id,
      fileId: this.state.deletedFile.file.id,
    });

    this.setState({
      showDeleteCVModal: false,
      deletedFile: null,
    });
  };

  renderCard() {
    return (
      <div className="candidate" id="detail-person">
        <div className="header-container">
          {this.state.profileLoading ? (
            <Loader mini />
          ) : (
            <button onClick={() => this.props.history.push('/main/persons')}>
              <span className="material-symbols-rounded">arrow_back_ios</span>
            </button>
          )}
          <h3>{I18n.t('persons.detail.title')}</h3>
        </div>
        <div className="person-name">
          <div className="name">
            <h4>
              {this.props.person.user_player.user.first_name +
                ' ' +
                this.props.person.user_player.user.last_name}
            </h4>
          </div>
          <div>
            <button
              className="campaign-button"
              disabled={!this.props.user.branch}
              onClick={() => {
                this.props.history.push('/main/campaign');
              }}
            >
              <p>
                <span className="material-symbols-filled icon">campaign</span>
                {I18n.t('clients.campaign')}{' '}
                <strong
                  className="campaign-name"
                  id="campaign-name"
                  data-tooltip-id="text-overflow-campaign"
                  data-tooltip-content={this.props.person.campaign.name}
                  onMouseLeave={this.handleHeights}
                >
                  {' '}
                  {this.props.person.campaign.name}
                </strong>
                <LargeTextTooltip
                  id="text-overflow-campaign"
                  idToOverflow="campaign-name"
                />
                <span className="material-symbols-rounded arrow">
                  arrow_forward_ios
                </span>
              </p>
            </button>
          </div>
        </div>
        <div className="card game-data">
          <PersonalData
            person={this.props.person}
            user={this.props.user}
            locale={this.props.locale}
            handleCopy={this.handleCopy}
          />
        </div>
        <div className="card">
          <div className="title-date">
            <p>
              <span className="material-symbols-filled icon">
                calendar_month
              </span>{' '}
              {I18n.t('persons.game_date')}
            </p>
          </div>
          <div className="separator"></div>
          <div className={`timeline`}>
            <ol>
              {this.props.userPlayerInformation?.results_x_invitation?.map(
                (item) => (
                  <li key={item.result.id}>
                    <div>
                      <time>{dayjs(item.created).format('DD/MM/YYYY')}</time>
                      <p>
                        {item.result.nwm_result_distance_match.profile[
                          `name_${this.props.locale}`
                        ].replace('/', '/ ')}
                      </p>
                    </div>
                  </li>
                ),
              )}
            </ol>
          </div>
        </div>
        <div className="card curriculum-container">
          <UploadFiles
            buttonIcon="attach_file"
            withPreview={false}
            extensions={
              '.jpeg,.pdf,.jpg,.png,.mp4,.MP4,.MOV,.mov,.mkv,.txt,.text'
            }
            title={I18n.t('persons.CV.title')}
            textButton={I18n.t('persons.CV.textButton')}
            textButtonWithFile={I18n.t('persons.CV.textButtonWithFile')}
            messageNoFile={I18n.t('persons.CV.messageNoFile')}
            icon="attach_file"
            rotateIcon={45}
            maxSize={5000000}
            disabled={this.props.user.user_admin}
            file={this.props.attachedFiles}
            deleteAttached={this.handleDeleteAttached}
            attachedFilesDeleted={this.props.attachedFilesDeleted}
            onChange={(file) => this.handleChangeCV(file)}
            loading={this.props.addCVStatus.loading}
            loadingfiles={this.props.getCVStatus.loading}
          />
        </div>
      </div>
    );
  }
  renderNotes() {
    let allNotes = [
      ...this.props.notes.filter((note) => note.featured),
      ...this.props.notes.filter((note) => !note.featured),
    ];
    return (
      <div className="notes-container">
        <div className="header-container">
          <button onClick={() => this.props.history.goBack()}>
            <span className="material-symbols-rounded">arrow_back_ios</span>
          </button>
          <h3>{I18n.t('persons.note')}</h3>
        </div>
        <div className="name-container">
          <h4>
            {this.props.person.user_player.user.first_name +
              ' ' +
              this.props.person.user_player.user.last_name}
          </h4>
          <div className="campaign-name-container">
            <span className="material-symbols-filled">campaign</span>
            <p>{I18n.t('clients.campaign')}</p>
            <label>{this.props.person.campaign.name}</label>
          </div>
        </div>
        <div className="notes">
          <div className="note-list">
            {allNotes.map((note, index) => {
              let classToAggregate = '';
              if (this.state.noteClicked.id === note.id) {
                if (this.state.noteClicked.index < index)
                  classToAggregate = 'animation-to-down';
                if (this.state.noteClicked.index > index)
                  classToAggregate = 'animation-to-up';
              } else {
                if (this.state.prevNotes.indexOf(note.id) < index)
                  classToAggregate = 'animation-to-down';
                if (this.state.prevNotes.indexOf(note.id) > index)
                  classToAggregate = 'animation-to-up';
              }
              return this.renderNoteCard(note, classToAggregate, index);
            })}
          </div>
        </div>
        <div className="add-note">
          <textarea
            placeholder={I18n.t('persons.note-placeholder', {
              name:
                this.props.person.user_player.user.first_name +
                ' ' +
                this.props.person.user_player.user.last_name,
            })}
            // maxLength={250}
            value={this.state.values.note.inputValue}
            onChange={(e) => {
              e.preventDefault();
              this.setState({
                ...this.state,
                values: {
                  ...this.state.values,
                  note: { inputValue: e.target.value },
                },
                postLength: e.target.value.length,
              });
            }}
            disabled={this.props.user.user_admin}
          ></textarea>
          <div className="send">
            {!this.props.user.user_admin ? (
              <span
                className={`${
                  this.state.values.note.inputValue.length > 250
                    ? 'error-note'
                    : ''
                }`}
              >
                {this.state.values.note.inputValue.length}/250{' '}
                {I18n.t('persons.characters')}
              </span>
            ) : (
              <div></div>
            )}
            <Button
              className="primary"
              text={I18n.t('persons.send')}
              disabled={
                !this.state.values.note.inputValue.length ||
                this.props.user.user_admin ||
                this.state.values.note.inputValue.length > 250
              }
              iconClass="send"
              iconButton
              onClick={() => {
                this.props.addNote({
                  description: this.state.values.note.inputValue,
                  invitation_id: this.props.person.id,
                  player_id: this.props.person.user_player.id,
                });
                this.setState({
                  ...this.state,
                  values: { ...this.state.values, note: { inputValue: '' } },
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  renderNoteCard(note, classToAdd, index) {
    return (
      <div className={`note-card ${note.featured}`} key={note.id}>
        <div className="line"></div>
        <div className={`${classToAdd} card`}>
          <div className="header">
            <div className="date">
              <span>
                {I18n.t(
                  `common.weekdays.${dayjs(note.created)
                    .format('dddd')
                    .toLowerCase()}`,
                )}
                {'  '}
                {dayjs(note.created).format('DD/MM/YYYY')}
              </span>
              <span>{dayjs(note.created).format('HH:mm[hs]')}</span>
            </div>
            <div className="buttons">
              {this.props.user.user_company === note.user_company.id && (
                <button
                  onClick={() =>
                    this.setState({
                      ...this.state,
                      showDeleteModal: true,
                      values: { ...this.state.values, noteToDelete: note },
                    })
                  }
                >
                  <span className="material-symbols-rounded">delete</span>
                </button>
              )}
              {this.props.user.user_company === note.user_company.id ? (
                <button
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      noteClicked: { id: note.id, index },
                      prevNotes: [
                        ...this.props.notes.flatMap((note) =>
                          note.featured ? note.id : [],
                        ),
                        ...this.props.notes.flatMap((note) =>
                          !note.featured ? note.id : [],
                        ),
                      ],
                    });
                    this.props.updateNote({
                      featured: !note.featured,
                      player_id: this.props.person.user_player.id,
                      note_id: note.id,
                    });
                  }}
                >
                  <span
                    className={`${
                      note.featured
                        ? 'material-symbols-rounded material-symbols-filled'
                        : 'material-symbols-rounded'
                    }`}
                  >
                    star
                  </span>
                </button>
              ) : (
                <p className="star-outlined">
                  <span
                    className={`${
                      note.featured
                        ? 'material-symbols-oulined'
                        : 'material-symbols-filled'
                    }`}
                  >
                    star
                  </span>
                </p>
              )}
            </div>
          </div>
          <p className={`description ${note.featured ? 'featured' : ''}`}>
            {note.description}
          </p>
          <span className={`user-name ${note.featured ? 'featured' : ''}`}>
            {note.user_company?.user?.full_name}
          </span>
        </div>
      </div>
    );
  }
}

export default PersonDetailScreen;
