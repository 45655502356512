import { connect } from 'react-redux';
import actions from '../../actions/index';
import PersonsScreen from '../components/PersonsScreen/PersonsScreen';
import { withRouter } from 'react-router';

const mapDispatchToProps = (dispatch) => ({
  selectPerson: (id) => dispatch(actions.persons.selectPerson(id)),
  updatePage: (page) => dispatch(actions.persons.updatePage(page, true)),
  updatePageSize: (pageSize) =>
    dispatch(actions.persons.updatePageSize(pageSize, true)),
  updateFilterValues: (filter) =>
    dispatch(actions.persons.updateFilterValues(filter)),
  filterPersons: (filter, resetPage) =>
    dispatch(
      actions.persons.filterPersons({ ...filter, getPersons: true }, resetPage),
    ),
  clearUiValue: (type, condition) =>
    dispatch(actions.persons.clearUiValue(type, condition)),
  getCountries: () => dispatch(actions.persons.getCountries()),
  getCities: () => dispatch(actions.persons.getCities()),
  getAllProfiles: (body) =>
    dispatch(actions.persons.getAllProfiles(body?.search)),
  getAllPartners: () => dispatch(actions.persons.getAllPartners()),
  demographicFilter: (latlng) =>
    dispatch(actions.persons.demographicFilter(latlng)),
  addRemovePersonToSelection: (result) =>
    dispatch(actions.persons.addRemovePersonToSelection(result)),
  toggleSelectionAll: () => dispatch(actions.persons.toggleSelectionAll()),
  clearSelection: () => dispatch(actions.persons.clearSelection()),
  clearPersonCustomFilters: () =>
    dispatch(actions.persons.clearPersonCustomFilters()),
  downloadProfileReport: (id, reportType) =>
    dispatch(actions.persons.downloadProfileReport(id, reportType)),
  sendPersonReportEmail: (values) =>
    dispatch(actions.persons.sendPersonReportEmail(values)),
  saveSearchInput: (value) => dispatch(actions.persons.saveSearchInput(value)),
  clearFilter: () => dispatch(actions.persons.clearFilter()),
  getAllCampaigns: ({ partners, campaign_dropdown, search }) =>
    dispatch(
      actions.campaigns.getAllCampaigns(partners, campaign_dropdown, search),
    ),
  migrateCampaign: (file) => dispatch(actions.persons.migrateCampaign(file)),
  selectAll: (flag) => dispatch(actions.persons.selectAll(flag)),
  getAllClients: (account_dropdown, view_branch, view_account) =>
    dispatch(
      actions.clients.getAllClients(
        account_dropdown,
        view_branch,
        view_account,
      ),
    ),
  shareFile: ({ id_invitation, sendCv, sendNotes, emails }) =>
    dispatch(
      actions.persons.shareFile({ id_invitation, sendCv, sendNotes, emails }),
    ),
  setCreditFilterClear: (value) =>
    dispatch(actions.common.setCreditFilterClear(value)),
  getLastMonth: () => dispatch(actions.dashboard.getLastMonthData()),
  massiveIeInvitation: (value) =>
    dispatch(actions.persons.massiveIeInvitation(value)),
  massiveIeInvitationResponse: () =>
    dispatch(actions.persons.massiveIeInvitationResponse()),
});

const mapStateToProps = (state, ownProps) => ({
  totalCount: state.persons.count,
  currentPage: state.persons.current,
  totalPages: state.persons.total_pages,
  pageSize: state.persons.page_size,
  allSelected: state.persons.allSelected,
  allLoaded: state.persons.allLoaded,
  allResultsSelected: state.persons.allResultsSelected,
  results: state.persons.results,
  start_date: state.dashboard.start_date,
  end_date: state.dashboard.end_date,

  desc: state.persons.desc,
  createStatus: state.persons.create,
  updateStatus: state.persons.update,
  updateUserStatus: state.persons.userUpdate,
  deleteStatus: state.persons.delete,
  dateStatus: state.dashboard.dateStatus,
  reportsStatus: state.persons.reportsStatus,
  shareFileStatus: state.persons.shareFileStatus,

  persons: state.persons.results,
  massiveIeResponse: state.persons.massiveInvitationStatus,
  profiles: state.persons.profiles,
  partners: state.clients.allClients,
  countries: state.persons.countries,
  cities: state.persons.cities,
  retrieveStatus: state.persons.retrieve,
  sendPersonReportEmailStatus: state.persons.sendPersonReportEmailStatus,
  filters: state.persons.filters,
  customFilter: state.persons.customFilter,
  filterApplied: state.persons.filterApplied,
  retrieveFilter: state.persons.retrieveFilter,
  historicalStartDate: state.persons.historicalStartDate,

  selection: state.persons.selection,

  savedSearchInput: state.persons.savedSearchInput,

  allCampaigns: state.campaigns.allCampaigns,
  allCampaignsStatus: state.campaigns.getAllCampaignsStatus,
  user: state.session.user,
  language: state.i18n.locale,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PersonsScreen));
