import actions from '../actions/index';
import { services } from './persons.services';
import {
  CREATE_PERSON,
  // GET_PERSONS,
  UPDATE_PAGE,
  UPDATE_PAGE_SIZE,
  UPDATE_PERSON,
  DELETE_PERSON,
  GET_ALL_PROFILES,
  DEMOGRAPHIC_FILTER,
  GET_ALL_PARTNERS,
  GET_JOB_COMPATIBILITY,
  GET_CAPABILITIES,
  GET_CITIES,
  GET_PROFILE,
  GET_COUNTRIES,
  FILTER_PERSONS,
  PATCH_USER,
  UPDATE_PERSONS_FILTER_VALUES,
  DOWNLOAD_PERSON_REPORT,
  SEND_PERSON_REPORT_EMAIL,
  MIGRATE_CAMPAIGN,
  MIGRATE_CAMPAIGN_RESPONSE,
  MIGRATE_CAMPAIGN_ERROR,
  TREND_EXPORT,
  SELECT_ALL,
  LOAD_ALL,
  GET_ALL_JOB_COMPATIBILITY,
  GET_USER_PLAYER_INFORMATION,
  GET_USER_PLAYER_INFORMATION_RESPONSE,
  ADD_NOTE,
  GET_NOTES,
  UPDATE_NOTE,
  DELETE_NOTE,
  ADD_CV,
  GET_CV,
  UPDATE_CV,
  SHARE_FILE,
  ADD_IE_INVITATION_RESPONSE,
  ADD_IE_INVITATION,
  SELECT_PERSON,
  MASSIVE_IE_INVITATION,
  DELETE_ATTACHED,
} from './persons.actions';
import { parseQueryObject } from '../utils/parser';
import { push } from 'connected-react-router';
import { notify } from 'react-notify-toast';
import { success, error } from '../utils/modals';
import dayjs from 'dayjs';
import { I18n } from 'react-redux-i18n';
import { BROWSER_LANGUAGE } from '../utils/constants';

const personsMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);
    const language = localStorage.getItem('language');
    const { selection, allSelected, allResultsSelected, page_size } =
      getState().persons;
    switch (action.type) {
      // case GET_PERSONS:
      //     const actionFilter = { ...getState().persons.customFilter };
      //     const filter = {
      //         ...actionFilter,
      //         page: (actionFilter.search.length > 0 || Object.keys(actionFilter).length > 2) ? 1 : getState().persons.current,
      //         page_size: getState().persons.page_size,
      //         lang: localStorage.getItem('language')
      //     };
      //     services.getPersons(parseQueryObject(filter))
      //     .then(data => dispatch(actions.persons.getPersonsResponse(data)))
      //     .catch((error) => dispatch(actions.persons.getPersonsError(error)));
      //     break;

      case FILTER_PERSONS:
        const customFilter = { ...getState().persons.customFilter };
        /* customFilter: {
                campaigns: [1]
                code: "asda"
                genders: ["MALE"]
                max_age: 19
                min_age: 18
                ordering: "-game_date"
                page: 1
                partners: [1]
                profiles: [1]
                search: ''
            } */

        // NOTE: delete items that go to the endpoint and not the body
        // endpointFilter.page will check if there is other filter other than ordering, if so
        // the page needs to be reset to 1.
        delete customFilter.search;
        delete customFilter.current;
        delete customFilter.page;
        // delete customFilter.ordering;
        // Request URL: http://34.206.199.137/api/person/filter/?search=jose&page=1&page_size=10&lang=es
        const endpointFilters = {
          search: getState().persons.customFilter.search,
          //ordering: getState().persons.customFilter.ordering,
          page: action.resetPage ? 1 : getState().persons.current,
          page_size: customFilter.statusCompleted
            ? ''
            : getState().persons.page_size,
          lang: language,
        };
        if (customFilter.page_size) {
          delete endpointFilters.page_size;
        }
        endpointFilters.page = endpointFilters.page ? endpointFilters.page : 1;
        if (endpointFilters.search?.length) delete action.newFilter.search;
        if (!action.filter.getPersons) {
          services
            .filterPersons(customFilter, parseQueryObject(endpointFilters))
            .then((data) =>
              dispatch(actions.persons.filterPersonsResponse(data)),
            )
            .catch((error) =>
              dispatch(actions.persons.filterPersonsError(error)),
            );
        } else {
          services
            .getPersons(customFilter, parseQueryObject(endpointFilters))
            .then((data) =>
              dispatch(actions.persons.filterPersonsResponse(data)),
            )
            .catch((error) =>
              dispatch(actions.persons.filterPersonsError(error)),
            );
        }
        break;

      case UPDATE_PAGE:
        {
          dispatch(
            actions.persons.filterPersons({
              isPageUpdate: true,
              getPersons: action.getPersons,
            }),
          );
        }
        break;

      case UPDATE_PAGE_SIZE:
        dispatch(
          actions.persons.filterPersons({
            isPageUpdate: true,
            getPersons: action.getPersons,
          }),
        );
        break;

      case UPDATE_PERSON:
        services
          .updatePerson(action.id, action.person)
          .then((data) => {
            dispatch(actions.persons.updatePersonResponse(data));
            dispatch(actions.persons.patchUser(action.user_id, action.user));
          })
          .catch((error) => dispatch(actions.persons.updatePersonError(error)));
        break;

      case PATCH_USER:
        services
          .patchUser(action.id, action.user)
          .then((data) => {
            dispatch(actions.persons.patchUserResponse(data));
            dispatch(push('/main/persons'));
          })
          .catch((error) => dispatch(actions.persons.patchUserError(error)));
        break;

      case DELETE_PERSON:
        services
          .deletePerson(action.id)
          .then((data) => {
            dispatch(actions.persons.deletePersonResponse(data));
            dispatch(push('/main/persons'));
          })
          .catch((error) => dispatch(actions.persons.deletePersonError(error)));
        break;

      case CREATE_PERSON:
        services
          .createPerson(action.body)
          .then((data) => {
            dispatch(push('/main/persons'));
            dispatch(actions.persons.createPersonResponse(data));
          })
          .catch((error) => dispatch(actions.persons.createPersonError(error)));
        break;

      case GET_ALL_PROFILES:
        services
          .getAllProfiles('?page_size=50', action.search)
          .then((data) =>
            dispatch(actions.persons.getAllProfilesResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.persons.getAllProfilesError(error)),
          );
        break;

      case GET_ALL_PARTNERS:
        services
          .getAllPartners('?page_size=50')
          .then((data) =>
            dispatch(actions.persons.getAllPartnersResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.persons.getAllPartnersError(error)),
          );
        break;

      case DEMOGRAPHIC_FILTER:
        services
          .demographicFilter(action.latlng)
          .then((data) =>
            dispatch(actions.persons.demographicFilterResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.persons.demographicFilterError(error)),
          );
        break;

      case GET_JOB_COMPATIBILITY:
        services
          .getJobCompatibility({
            positions: action.positions,
            results: allResultsSelected.length
              ? allResultsSelected.map((item) => item.id_result)
              : selection.map((item) => item.id_result),
          })
          .then((data) => {
            dispatch(actions.profile.removeSelectedSkill());
            dispatch(actions.persons.getJobCompatibilityResponse(data));
          })
          .catch((error) =>
            dispatch(actions.persons.getJobCompatibilityError(error)),
          );
        break;

      case GET_CAPABILITIES:
        services
          .getCapabilities(action.job, ...getState().persons.selection)
          .then((data) =>
            dispatch(actions.persons.getCapabilitiesResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.persons.getCapabilitiesError(error)),
          );
        break;

      case GET_COUNTRIES:
        services
          .getCountries()
          .then((data) => dispatch(actions.persons.getCountriesResponse(data)))
          .catch((error) => dispatch(actions.persons.getCountriesError(error)));
        break;

      case GET_PROFILE:
        services
          .getProfile(action.id)
          .then((data) => dispatch(actions.persons.getProfileResponse(data)))
          .catch((error) => dispatch(actions.persons.getProfileError(error)));
        break;

      case GET_CITIES:
        services
          .getCities(action.country_id)
          .then((data) => dispatch(actions.persons.getCitiesResponse(data)))
          .catch((error) => dispatch(actions.persons.getCitiesError(error)));
        break;

      case DOWNLOAD_PERSON_REPORT:
        const lang = language;
        const now = new Date();
        const downloadName = {
          individual: {
            es: 'Informes Individuales',
            en: 'Individual Reports',
            pt: 'Relatórios Individuais',
          },
          empresa: {
            es: 'Informes Empresas',
            en: 'Reports for Companies',
            pt: 'Relatórios para Empresas',
          },
        };
        const singleDownloadNames = {
          individual: {
            es: 'Individual',
            en: 'Individual',
            pt: 'Individual',
          },
          empresa: {
            es: 'Empresa',
            en: 'Company',
            pt: 'Empresa',
          },
        };
        services
          .downloadProfileReport(action.ids, action.reportType)
          .then((data) => {
            const blob = new Blob([data]);
            const persons = getState().persons.results;
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            if (action.ids.length === 1 || data.type === 'application/pdf') {
              let persons_ = action.ids.filter((item) => {
                let person = persons.find(
                  (p) =>
                    p.conductual.result.id === item.id_result &&
                    p.profile.id !== 27,
                );
                if (person) {
                  return true;
                }
              });
              const resultId = persons_[0].id_result;

              let person = persons.find(
                (p) => p.conductual.result.id === resultId,
              );
              link.download = person
                ? `${person.user.full_name} - ${
                    singleDownloadNames[action.reportType][lang]
                  }.pdf`
                : 'Resultado.pdf';
              link.click();
              if (action.data.length > 0) {
                action.data.forEach((d) => {
                  const blob = new Blob([d]);
                  const link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);

                  link.download = `${
                    downloadName[action.reportType][lang]
                  } ${now.getFullYear()}-${
                    now.getMonth() + 1
                  }-${now.getDate()}_${now.getHours()}.${now.getMinutes()}.${now.getSeconds()}.zip`;
                  link.click();
                });
              }
              dispatch(actions.persons.downloadProfileReportResponse(data));
            } else {
              if (action.ids.length > 25) {
                dispatch(
                  actions.persons.downloadProfileReport(
                    action.ids.splice(25),
                    action.reportType,
                    [...action.data, data],
                  ),
                );
              } else {
                let datas = [data];
                if (action.data.length > 0) {
                  datas = [...action.data, data];
                }
                datas.forEach((d) => {
                  const blob = new Blob([d]);
                  const link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);

                  link.download = `${
                    downloadName[action.reportType][lang]
                  } ${now.getFullYear()}-${
                    now.getMonth() + 1
                  }-${now.getDate()}_${now.getHours()}.${now.getMinutes()}.${now.getSeconds()}.zip`;
                  link.click();
                });

                dispatch(actions.persons.downloadProfileReportResponse(data));
                dispatch(actions.persons.clearSelection());
              }
            }
          })
          .catch((error) => {
            dispatch(actions.persons.downloadProfileReportError(error));
          });
        break;

      case SEND_PERSON_REPORT_EMAIL:
        services
          .sendPersonReportEmail(
            action.value,
            action.id_invitation,
            action.campaign,
          )
          .then((response) =>
            dispatch(actions.persons.sendPersonReportEmailResponse(response)),
          )
          .catch((error) =>
            dispatch(actions.persons.sendPersonReportEmailError(error)),
          );
        break;

      case MIGRATE_CAMPAIGN:
        const bodyFormData = new FormData();
        bodyFormData.append('migration', action.file);
        services
          .migrateCampaign(bodyFormData)
          .then((response) =>
            dispatch(actions.persons.migrateCampaignResponse(response)),
          )
          .catch((error) =>
            dispatch(actions.persons.migrateCampaignError(error)),
          );
        break;

      case UPDATE_PERSONS_FILTER_VALUES:
        dispatch(
          actions.persons.filterPersons({
            ...action.filter,
            ...getState().persons.customFilter,
          }),
        );
        break;

      case MIGRATE_CAMPAIGN_ERROR:
        notify.show(`${action.error.data}`, 'custom', 5000, error);
        break;

      case MIGRATE_CAMPAIGN_RESPONSE:
        const message_success = {
          es: 'Migración Completada',
          en: 'Migration Completed',
          pt: 'Migração Concluída',
        };
        notify.show(`${message_success[language]}`, 'custom', 5000, success);
        dispatch(actions.persons.filterPersons());
        break;

      case TREND_EXPORT:
        // const data = action.data.map(
        //   ({
        //     first_name,
        //     last_name,
        //     result_profile,
        //     skills_range,
        //     profile_match,
        //     profile_id,
        //   }) => ({
        //     name: `${first_name} ${last_name}`,
        //     profile:
        //       result_profile ||
        //       profile_match.filter(
        //         (match) => match.profile_one.id === profile_id,
        //       )[0]?.profile_one.name ||
        //       '---',
        //     skill: skills_range
        //       ? skills_range.map(({ min }) => min)
        //       : action.columns
        //           .filter((col) => col.profile)
        //           .map(
        //             (col) =>
        //               profile_match.find(
        //                 (match) =>
        //                   match.profile_one.id === profile_id &&
        //                   match.profile_two.id === col.profile,
        //               )?.value || '---',
        //           ),
        //   }),
        // );
        services
          .exportTrend({
            data: action.data,
            capabilities: action.capabilities,
            results: allSelected
              ? allResultsSelected.map((item) => item.id_result)
              : selection.map((item) => item.id_result),
          })
          .then((data) => {
            const fullname = action.capabilities
              ? I18n.t('persons.groupTrends.capabilitiesCompatibility')
              : I18n.t('persons.groupTrends.jobCompatibility');
            const blob = new Blob([data]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fullname}.xlsx`;
            link.click();
            dispatch(actions.persons.trendExportResponse(data));
          })
          .catch((error) => dispatch(actions.persons.trendExportError(error)));
        break;

      case SELECT_ALL:
        const flag = action.flag;
        if (flag) {
          dispatch(actions.persons.loadAll(1));
        } else {
          dispatch(actions.persons.clearSelection());
        }
        break;
      //OPTIONS

      case LOAD_ALL:
        const pageFilter = {
          //page: action.page,
          lang: localStorage.getItem('language'),
          //page_size,
        };
        services
          .getPersons(
            getState().persons.customFilter,
            parseQueryObject(pageFilter),
          )
          .then((data) => {
            const currentResults = data.results.map((item) => ({
              id_result: item.conductual.result.id,
              id_invitation: item.conductual.id,
              campaign: item.campaign,
              profile: item.profile,
              full_name: item.user.full_name,
              user_id: item.user_player.id,
            }));

            dispatch(actions.persons.saveAll(currentResults));
            dispatch(actions.persons.allLoaded());
          })
          .catch((error) =>
            dispatch(actions.persons.filterPersonsError(error)),
          );
        break;

      case GET_ALL_JOB_COMPATIBILITY:
        services
          .getJobCompatibility({
            positions: action.positions,
            results: allSelected ? allResultsSelected : selection,
          })
          .then((data) =>
            dispatch(actions.persons.getAllJobCompatibilityResponse(data)),
          )
          .catch((error) =>
            dispatch(actions.persons.getAllJobCompatibilityError(error)),
          );
        break;

      case GET_USER_PLAYER_INFORMATION:
        services
          .getUserPlayerInformation(action.id_invitation)
          .then((data) =>
            dispatch(actions.persons.getUserPlayerInformationResponse(data)),
          )
          .catch((error) => console.log('error', error));
        break;

      case ADD_NOTE:
        services
          .addNote(action.body)
          .then((data) => {
            dispatch(actions.persons.addNoteResponse(data));
            dispatch(actions.persons.getNotes(action.body.player_id));
          })
          .catch((error) => dispatch(actions.persons.addNoteError(error)));
        break;

      case UPDATE_NOTE:
        services
          .updateNote(action.body)
          .then((data) => {
            dispatch(actions.persons.updateNoteResponse(data));
            dispatch(actions.persons.getNotes(action.body.player_id));
          })
          .catch((error) => dispatch(actions.persons.updateNoteError(error)));
        break;

      case DELETE_NOTE:
        services
          .deleteNote(action.body.note_id)
          .then((data) => {
            dispatch(actions.persons.deleteNoteResponse(data));
            dispatch(actions.persons.getNotes(action.body.player_id));
          })
          .catch((error) => dispatch(actions.persons.deleteNoteError(error)));
        break;

      case GET_NOTES:
        services
          .getNotes(action.id_user_player)
          .then((data) => dispatch(actions.persons.getNotesResponse(data)))
          .catch((error) => dispatch(actions.persons.getNotesError(error)));
        break;

      case ADD_CV:
        services
          .addCV(action.body)
          .then((data) => {
            dispatch(actions.persons.addCVResponse(data));
            dispatch(actions.persons.getCV(action.body.id_user_player));
          })
          .catch((error) => dispatch(actions.persons.addCVError(error)));
        break;

      case GET_CV:
        services
          .getCV(action.id_user_player)
          .then((data) => {
            dispatch(actions.persons.getCVResponse(data));
          })
          .catch((error) => dispatch(actions.persons.getCVError(error)));
        break;

      // case UPDATE_CV:
      //   services
      //     .updateCV(action.body)
      //     .then((data) => {
      //       dispatch(actions.persons.updateCVResponse(data));
      //       dispatch(actions.persons.getCV(data.nwm_user_playerId));
      //     })
      //     .catch((error) => dispatch(actions.persons.updateCVError(error)));
      //   break;

      case DELETE_ATTACHED:
        services
          .deleteAttached(action.id_user_player, action.fileId)
          .then((data) => {
            dispatch(actions.persons.deleteAttachedResponse(data));
            dispatch(
              actions.persons.getCV(action.id_user_player.id_user_player),
            );
          })
          .catch((error) =>
            dispatch(actions.persons.deleteAttachedError(error)),
          );
        break;

      case SHARE_FILE:
        services
          .shareFile(action.body)
          .then((data) => {
            dispatch(actions.persons.shareFileResponse(data));
          })
          .catch((error) => dispatch(actions.persons.shareFileError(error)));
        break;
      case ADD_IE_INVITATION:
        services
          .addIeInvitation(
            action.body.id_campaign,
            action.body.email,
            action.body.lang,
          )
          .then(() => {
            dispatch(
              actions.persons.getUserPlayerInformation(
                action.body.id_invitation,
              ),
            );
            dispatch(actions.persons.addIeInvitationResponse());
          })
          .catch((error) =>
            dispatch(actions.persons.addIeInvitationError(error)),
          );
        break;
      case MASSIVE_IE_INVITATION:
        services
          .massiveIeInvitation(action.body)
          .then(() => {
            dispatch(actions.persons.massiveIeInvitationResponse());
          })
          .catch((error) =>
            dispatch(actions.persons.massiveIeInvitationError(error)),
          );
        break;
      case SELECT_PERSON:
        services
          .getPerson({ code: action.id }, parseQueryObject({ lang: language }))
          .then((data) => {
            dispatch(actions.persons.selectPersonResponse(data));
            dispatch(push('/main/person/' + action.id));
          });
        break;
      default:
        break;
    }
  };

export default personsMiddleware;
