import React, { Component } from 'react';
import FormDropdown from '../Dropdown/FormDropdown';
import Loader from '../Loader';
import './ToolboxColumn.sass';
import { I18n } from 'react-redux-i18n';
import SquareButton from '../SquareButton';
import { remove } from 'lodash';
class ToolboxColumn extends Component {
  static defaultProps;
  constructor(props) {
    super(props);
    this.state = {
      titles: [],
      subtitles: [],
      captions: [],
      filter: {
        title: '',
        subtitle: { description: '' },
        caption: { description: '' },
        partner_ids: {
          code: undefined,
          description: I18n.t('clients.accountButton'),
        },
        branchs: {
          code: undefined,
          description: I18n.t('clients.filiar'),
        },
      },
      filterValues: {},
      loading: false,
      isAdmin: false,
      isUserAccount: false,
    };
  }
  state = {
    titles: [],
    subtitles: [],
    captions: [],
    filter: {
      title: '',
      subtitle: { description: '' },
      caption: { description: '' },
    },
    loading: false,
  };
  async componentDidMount() {
    let titles = [];
    let subtitles = [];
    let captions = [];

    if (this.props.campaigns && this.props.profiles) {
      this.setState({
        ...this.state,
        subtitles: this.props.profiles,
        captions: this.props.campaigns,
      });
    } else {
      await this.props.items.map((item) => {
        let subtitleExist;
        let captionExist;
        if (this.props.backendFetch) {
          subtitleExist = subtitles.findIndex(
            (subtitle) =>
              subtitle.subtitle?.description === item.subtitle?.description,
          );
          captionExist = captions.findIndex(
            (caption) =>
              caption.caption?.description === item.caption?.description,
          );
        } else {
          subtitleExist = subtitles.findIndex(
            (subtitle) => subtitle.subtitle === item.subtitle,
          );
          captionExist = captions.findIndex(
            (caption) => caption.caption === item.caption,
          );
        }
        titles.push({ title: item.title });
        if (subtitleExist === -1) subtitles.push({ subtitle: item.subtitle });
        if (captionExist === -1) captions.push({ caption: item.caption });
      });
      this.setState({
        ...this.state,
        titles,
        subtitles,
        captions,
      });
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      let titles = [];
      let subtitles = [];
      let captions = [];
      if (this.props.toolboxFilter) {
        this.setState({
          ...this.state,
          filter: {
            title: this.props.toolboxFilter.title || '',
            subtitle: this.props.toolboxFilter.subtitle || '',
            caption: this.props.toolboxFilter.caption || '',
          },
        });
      }
      if (this.props.campaigns && this.props.profiles) {
        this.setState({
          ...this.state,
          subtitles: this.props.profiles,
          captions: this.props.campaigns,
        });
      } else {
        await this.props.items.map((item) => {
          let subtitleExist;
          let captionExist;
          if (this.props.backendFetch) {
            subtitleExist = subtitles.findIndex(
              (subtitle) =>
                subtitle.subtitle?.description === item.subtitle?.description,
            );
            captionExist = captions.findIndex(
              (caption) =>
                caption.caption?.description === item.caption?.description,
            );
          } else {
            subtitleExist = subtitles.findIndex(
              (subtitle) => subtitle.subtitle === item.subtitle,
            );
            captionExist = captions.findIndex(
              (caption) => caption.caption === item.caption,
            );
          }

          titles.push({ title: item.title });
          if (subtitleExist === -1) subtitles.push({ subtitle: item.subtitle });
          if (captionExist === -1) captions.push({ caption: item.caption });
        });
        this.setState({
          ...this.state,
          titles,
          subtitles: this.props.profiles || subtitles,
          captions,
        });
      }
    }
  }

  handleSelect = async (name, option) => {
    this.setState(
      {
        ...this.state,
        filter: {
          ...this.state.filter,
          [name]: option,
        },
      },
      () => {
        if (this.props.backendFetch) {
          this.props.fetch(this.state.filter);
          if (name === 'partner_ids') {
            this.setState(
              {
                ...this.state,
                filter: {
                  ...this.state.filter,
                  caption: '',
                },
              },
              () => {
                this.props.fetch(this.state.filter);
              },
            );
            this.props.getBranchs({
              account_id: this.state.filter.partner_ids.code,
            });
          }
          if (name === 'branchs') {
            this.setState(
              {
                ...this.state,
                filter: {
                  ...this.state.filter,
                  caption: '',
                },
              },
              () => {
                this.props.fetch(this.state.filter);
              },
            );
          }
        }
        if (this.props.updateToolboxFilter) {
          let name;
          switch (this.props.name) {
            case 'selectedPersons':
              name = 'toolboxFilterPersons';
              break;
            case 'selectedJobs':
              name = 'toolboxFilterJobs';
              break;
            case 'selectedSkills':
              name = 'toolboxFilterSkills';
              break;
            default:
              break;
          }
          this.props.updateToolboxFilter(name, this.state.filter);
        }
      },
    );
  };
  switchTexts = (tab, section) => {
    if (section === 'search-bar') {
      switch (tab) {
        case 'selectedJobs':
          return I18n.t('toolbox.searchJob');
        case 'selectedPersons':
          return I18n.t('toolbox.searchPerson');
        case 'selectedSkills':
          return I18n.t('toolbox.searchSkill');
        default:
          break;
      }
    }
  };

  render() {
    const { title, headerIcon, items, handleSelect, name, selected } =
      this.props;

    let selectedIds = selected.map((s) => s.id);
    let itemsFilteredLength = this.props?.items?.filter(
      (r) =>
        !selectedIds.includes(r.id) &&
        (this.props.backendFetch
          ? true
          : r?.title
              ?.toLowerCase()
              .includes(this.state.filter?.title?.toLowerCase()) &&
            (r?.subtitle
              ? r?.subtitle.includes(this.state.filter?.subtitle?.description)
              : true) &&
            (r?.caption
              ? r?.caption.includes(this.state.filter?.caption?.description)
              : true)),
    ).length;
    let totalCount;
    if (
      (this.props.name === 'selectedJobs' ||
        this.props.name === 'selectedSkills') &&
      itemsFilteredLength > 8
    ) {
      totalCount = 8;
    } else {
      totalCount = itemsFilteredLength + selected.length;
    }
    return (
      <div className={`toolbox-column-container ${this.props.className}`}>
        <div className="toolbox-column-head">
          <div className="header">
            <p>{title}</p>
            {headerIcon}
          </div>
          <div
            className={`toolbox-column-filters ${
              this.props.isUserAccount ? 'user-account' : ''
            }`}
          >
            {this.props.isAdmin &&
            this.state.subtitles.length > 2 &&
            this.props.name === 'selectedPersons' ? (
              <FormDropdown
                name={'partner_ids'}
                placeholder={
                  this.isSuperDashboard
                    ? I18n.t('clients.accountButton')
                    : I18n.t('clients.identifier')
                }
                change={this.handleSelect}
                search={this.props.getAllClients}
                value={{ inputValue: this.state.filter.partner_ids }}
                options={this.props.allClients.map((client) => ({
                  code: client.id,
                  description: client.name,
                }))}
                cleanOption
                showEmptyOption
                getAllClientsPrompts={{
                  account_dropdown: true,
                  view_branch: false,
                  view_account: true,
                }}
                dropDownClean={this.props.dropDownClean}
              />
            ) : (
              ''
            )}
            {(this.props.isAdmin || this.props.isUserAccount) &&
            this.state.subtitles.length > 2 &&
            this.props.name === 'selectedPersons' ? (
              <FormDropdown
                name={'branchs'}
                placeholder={I18n.t('clients.filiar')}
                change={this.handleSelect}
                search={true}
                //addOption={this.handleFiltersChange}
                value={{ inputValue: this.state.filter.branchs }}
                options={this.props.branchs?.map((client) => {
                  return {
                    code: client.id,
                    description: client.name,
                  };
                })}
                cleanOption
                showEmptyOption
                getBranchsPrompts={{
                  account_dropdown: true,
                  view_branch: true,
                  view_account: false,
                }}
                dropDownClean={this.props.dropDownClean}
              />
            ) : (
              ''
            )}

            {this.props.name === 'selectedPersons' && (
              <FormDropdown
                value={{
                  inputValue: {
                    description: this.state.filter?.caption?.description,
                    label: I18n.t('campaigns.identifier'),
                    code: this.state.filter?.caption.code || null,
                  },
                }}
                change={this.handleSelect}
                name={'caption'}
                search={true}
                options={this.state?.captions.map((captions, key) => ({
                  code: captions?.caption?.code || key,
                  description:
                    captions?.caption?.description || captions.caption,
                }))}
                cleanOption
                dropDownClean={this.props.dropDownClean}
              />
            )}
            {(this.props.name === 'selectedPersons' ||
              this.props.name === 'selectedJobs') && (
              <FormDropdown
                name={'subtitle'}
                value={{
                  inputValue: {
                    description: this.state.filter?.subtitle?.description,
                    label: I18n.t('profiles.title'),
                    code: this.state.filter?.subtitle.code || null,
                  },
                }}
                search={true}
                options={this.state?.subtitles?.map((subtitles, key) => ({
                  code: subtitles?.subtitle?.code || key,
                  description:
                    subtitles?.subtitle?.description || subtitles?.subtitle,
                }))}
                cleanOption
                change={this.handleSelect}
                dropDownClean={this.props.dropDownClean}
              />
            )}

            <div className="search-input">
              <span className="material-symbols-rounded search-icon">
                search
              </span>
              <input
                name="title"
                type="text"
                id={this.props.name}
                value={this.state.filter.title}
                className={`${this.state.filter.title !== '' && 'active'}`}
                onChange={(e) =>
                  this.setState(
                    {
                      ...this.state,
                      filter: {
                        ...this.state.filter,
                        title: e.target.value,
                      },
                    },
                    () => {
                      if (this.props.backendFetch) {
                        {
                          if (this.searchTimeout)
                            clearTimeout(this.searchTimeout);
                          this.searchTimeout = setTimeout(
                            function () {
                              this.props.fetch(this.state.filter);
                            }.bind(this),
                            500,
                          );
                        }
                      }
                      if (this.props.updateToolboxFilter) {
                        let name;
                        switch (this.props.name) {
                          case 'selectedPersons':
                            name = 'toolboxFilterPersons';
                            break;
                          case 'selectedJobs':
                            name = 'toolboxFilterJobs';
                            break;
                          case 'selectedSkills':
                            name = 'toolboxFilterSkills';
                            break;
                          default:
                            break;
                        }
                        this.props.updateToolboxFilter(name, this.state.filter);
                      }
                    },
                  )
                }
                placeholder={this.switchTexts(name, 'search-bar')}
              />
            </div>
            <p
              onClick={() =>
                this.props.handleSelect(this.props.name, 'remove-all')
              }
              className={`remove-selection ${
                this.props?.selected?.length ? '' : 'invisible'
              }`}
            >
              <span className="material-symbols-rounded">close</span>
              {I18n.t('toolbox.clearSelection')}
            </p>
          </div>
        </div>
        <div className="toolbox-column-body">
          <div
            id="scroll-area"
            className={`items ${name === 'selectedSkills' && 'long-scroll'} ${
              selected.length ? 'some-items-selected' : ''
            } ${
              (this.props.isAdmin || this.props.isUserAccount) && 'short-scroll'
            }`}
          >
            {items.length > 0 || selected.length > 0 ? (
              selected
                .concat(
                  items.filter(
                    (r) =>
                      !selectedIds.includes(r.id) &&
                      (this.props.backendFetch
                        ? true
                        : r?.title
                            ?.normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            ?.toLowerCase()
                            .includes(
                              this.state.filter?.title
                                ?.normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .toLowerCase(),
                            ) &&
                          (r?.subtitle
                            ? r?.subtitle
                                ?.normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                ?.includes(
                                  this.state.filter?.subtitle?.description
                                    ?.normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, ''),
                                )
                            : true) &&
                          (r?.caption
                            ? r?.caption
                                ?.normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                ?.includes(
                                  this.state.filter?.caption?.description
                                    ?.normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, ''),
                                )
                            : true)),
                  ),
                )
                .map((item, index) => {
                  let length = [];
                  if ('title' in item) {
                    length.push(1);
                  }
                  if ('subtitle' in item) {
                    length.push(1);
                  }
                  if ('caption' in item) {
                    length.push(1);
                  }
                  if (
                    item.subtitle?.description === undefined &&
                    this.props.name === 'selectedPersons'
                  )
                    return;
                  return (
                    <>
                      <SquareButton
                        id={item.id}
                        key={item.id}
                        class={index + 1 === items.length ? 'last-item' : ''}
                        description={item.description}
                        change={handleSelect}
                        name={name}
                        setValue={item}
                        disabled={
                          (this.props.name === 'selectedJobs' ||
                            this.props.name === 'selectedSkills') &&
                          this.props.selected.length >= 8 &&
                          !this.props?.selected?.find(
                            (select) => select.id === item.id,
                          )
                        }
                        value={{
                          inputValue: selected.find(
                            (select) => select.id === item.id,
                          )
                            ? true
                            : false,
                        }}
                        labelBody={
                          <div className={`item value-${length.length}`}>
                            <div className="title">{item.title}</div>
                            <div className="sub-title">
                              {item.subtitle?.description
                                ? item.subtitle.description
                                : typeof item.subtitle !== 'object'
                                ? item.subtitle
                                : ''}
                            </div>
                            <div className="caption">
                              {item.caption?.description
                                ? item.caption.description
                                : typeof item.caption !== 'object'
                                ? item.caption
                                : ''}
                            </div>
                          </div>
                        }
                      />
                    </>
                  );
                })
            ) : this.props.totalCount === 0 ||
              this.props.totalCount === undefined ? (
              ''
            ) : (
              <Loader mini />
            )}
            {this.props.loading && <Loader mini />}
          </div>
        </div>
        <div className="toolbox-column-footer">
          <div className="bottom-text">
            <p className="selected">
              {I18n.t('toolbox.selected', {
                selected: this.props?.selected?.length,
                total: this.props.totalCount,
              })}
            </p>
            {this.props.name.includes('selectedPersons') && (
              // (this.state.filter.subtitle.description !== '' ||
              //   this.state.filter.caption.description !== '') && (
              <p
                className="select-all"
                onClick={() =>
                  this.props.handleSelect(this.props.name, 'add-all', items)
                }
              >
                {I18n.t('toolbox.selectAll')}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ToolboxColumn;
