import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import Button from '../Button';
import SquareButton from '../SquareButton';
import './DownloadReportModal.sass';
class DownloadReportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndividual: false,
      selectedCompany: false,
    };
  }
  changeState(key) {
    if (key === 'individual') {
      if (this.state.selectedIndividual)
        this.setState({
          ...this.state,
          selectedIndividual: false,
          // selectedCompany: true,
        });
      else
        this.setState({
          ...this.state,
          selectedIndividual: true,
          // selectedCompany: false,
        });
    }
    if (key === 'company') {
      if (this.state.selectedCompany)
        this.setState({
          ...this.state,
          // selectedIndividual: true,
          selectedCompany: false,
        });
      else
        this.setState({
          ...this.state,
          // selectedIndividual: false,
          selectedCompany: true,
        });
    }

    console.log(this.state);
  }
  render() {
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.props.onHide}
          className="export-excel-modal"
        >
          <Modal.Header className={'modal-header'}>
            <div className="header">
              <div class="material-symbols-rounded">download</div>
              <p className="title">{I18n.t(`persons.downloadReport`)}</p>
            </div>
          </Modal.Header>
          <Modal.Body className="options">
            <SquareButton
              label={I18n.t('persons.individual')}
              name={'individual'}
              value={{ inputValue: this.state.selectedIndividual }}
              setValue={!this.state.selectedIndividual}
              change={() => this.changeState('individual')}
            />
            <SquareButton
              label={I18n.t('persons.partner')}
              name={'company'}
              value={{ inputValue: this.state.selectedCompany }}
              setValue={!this.state.selectedCompany}
              change={() => this.changeState('company')}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="text-link-button"
              text={I18n.t('buttons.cancel')}
              onClick={this.props.onHide}
            />
            <Button
              className="primary"
              text={I18n.t('buttons.discharge')}
              disabled={
                !this.state.selectedCompany && !this.state.selectedIndividual
              }
              onClick={() => {
                if (
                  this.state.selectedCompany &&
                  !this.state.selectedIndividual
                )
                  this.props.action('reports', { code: 'empresa' });
                if (
                  !this.state.selectedCompany &&
                  this.state.selectedIndividual
                )
                  this.props.action('reports', { code: 'individual' });
                if (
                  this.state.selectedCompany &&
                  this.state.selectedIndividual
                ) {
                  this.props.action('reports', { code: 'individual' });
                  setTimeout(
                    () => this.props.action('reports', { code: 'empresa' }),
                    1000,
                  );
                }
                this.props.onHide();
              }}
            />
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default DownloadReportModal;
