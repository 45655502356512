import React from 'react';

// TODO: use i18n here?
const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

// const states = [
//     ""
// ];

export const parseQueryObject = (object) =>
  '?' +
  Object.keys(object)
    .filter((key) => object[key])
    .map((key) => `${key}=${object[key]}`)
    .join('&');

export const parseDate = (date) => {
  if (!date instanceof Date) date = new Date(date);
  return date && date instanceof Date && !isNaN(date)
    ? `${months[date.getMonth()].substr(
        0,
        3,
      )} ${date.getDate()}, ${date.getFullYear()}`
    : '';
};

export const parseDateTime = (date) => {
  if (!date instanceof Date) date = new Date(date);
  return date && date instanceof Date && !isNaN(date)
    ? `${months[date.getMonth()].substr(
        0,
        3,
      )} ${date.getDate()}, ${date.getFullYear()} ${
        (date.getHours() < 10 ? '0' : '') + date.getHours()
      }:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`
    : '';
};

export const parseBackDate = (date) => {
  if (!date instanceof Date && !isNaN(date)) date = new Date(date);
  return date && date instanceof Date && !isNaN(date)
    ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    : date || '';
};

export const parseHistoryDate = (date) => {
  const localDate = new Date(date);
  return (
    parseDate(localDate) +
    ' ' +
    localDate.getHours() +
    ':' +
    localDate.getMinutes()
  );
};
export const parseBackDateInverse = (date) => {
  const split = date.split('-');
  return date && parseDate(new Date(split[0], split[1] - 1, split[2]));
};

export const parseState = (state) =>
  state
    .split('-')
    .map((word) => word[0] + word.substr(1, word.length).toLowerCase())
    .join(' ');

export const parseLocaleString = (number) => {
  if (number[0] !== '$') return number.split(',').join('');
  return parseFloat(number.split(',').join('').split(' ')[1]);
};

export const parseLargeText = (text) => {
  if (!text) return '-';
  return text?.length > 50 ? `${text.substring(0, 50)}...` : text;
};

export const parseSize = (size) => {
  if (size < 1000) {
    return size + ' bytes';
  } else if (size > 1000 && size < 1000000) {
    return (parseFloat(size) / 1000).toFixed(2) + ' KB';
  } else if (size > 1000000 && size < 1000000000) {
    return (parseFloat(size) / 1000000).toFixed(2) + ' MB';
  } else {
    return (parseFloat(size) / 1000000000).toFixed(2) + ' GB';
  }
};

export const parsePayor = (payor) => {
  return payor
    .split('_')
    .map((word) => word[0] + word.substr(1, word.length).toLowerCase())
    .join(' ');
};

export const parseAttSize = (size) => {
  const number = parseFloat(size.split(' ')[0]);
  const units = size.split(' ')[1];
  let factor = 1;
  if (units === 'KB') {
    factor = 1000;
  } else if (units === 'MB') {
    factor = 1000000;
  } else if (units === 'GB') {
    factor = 1000000000;
  }
  return number * factor;
};

export const parseNewlines = (value, className) => {
  return value.split('\n').map((i) => <p className={className}>{i}</p>);
};

// regex got from https://emailregex.com/
export const emailIsValid = (email) => {
  /*eslint-disable */
  const emailRegex =
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  /*eslint-enable */
  let er = RegExp(emailRegex);
  return email.match(er);
};

export const getAge = (dateString) => {
  let splitted = dateString.split('-');
  var today = new Date();
  var birthDate = new Date(splitted[0], splitted[1], splitted[2]);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
