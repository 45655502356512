import React from 'react';
import { Component } from 'react';
import './UserDetailScreen.css';
import Input from '../../../components/common/Input';
import { I18n, i18nReducer } from 'react-redux-i18n';
import Dropdown from '../../../components/common/Dropdown';
import Button from '../../../components/common/Button';
import ChangePasswordModal from '../../../components/common/ChangePasswordModal';
import { notify } from 'react-notify-toast';
import { success, error } from '../../../utils/modals';
import Loader from '../../../components/common/Loader';
import { languagesCodes } from '../../../i18n/index';
import regex from '../../../utils/regex';
import SquareButton from '../../../components/common/SquareButton';
import UpdateUserModal from '../../../components/common/UpdateUserModal/UpdateUserModal';
import Tooltip from '../../../components/common/Tooltip';
import LanguageSelector from '../../../components/common/LanguageSelector/LanguageSelector';
import { Tabs, Tab } from 'react-bootstrap';
import FormDropdown from '../../../components/common/Dropdown/FormDropdown';
import MultiSelect from '../../../components/common/MultiSelect/index';
import i18next from 'i18next';
import {
  SnackBar,
  successMessage,
  errorMessage,
} from '../../../utils/snackbar';
const emptyState = {
  data: {
    user_groups: { inputValue: [] },
  },
  tempAccount: { inputValue: '' },
  tempBranch: { inputValue: '' },
  tempPermissionsAccount: { inputValue: [] },
  tempPermissionsBranch: { inputValue: [] },
  allItems: [
    { key: 'admin', title: 'Usuarios Locales' },
    { key: 'account', title: 'Usuarios cuenta' },
    { key: 'branch', title: 'Usuarios Filiales' },
  ],

  values: {
    first_name: { inputValue: '', required: true, validation: false },
    last_name: { inputValue: '', required: true, validation: false },
    password: { inputValue: '', required: true, validation: false },
    repeatPassword: { inputValue: '', required: true, validation: false },
    email: { inputValue: '', required: true, validation: false },
    user_group: { inputValue: '', required: true, validation: false },
    role: { inputValue: '', required: true, validation: false },
    partner: { inputValue: '', required: true, validation: false },
    partner_id: { inputValue: -1, required: true, validation: false },
    language: { inputValue: '', required: true, validation: false },
    group_ids: { inputValue: [], required: true, validation: false },
    user_service_login: { inputValue: false },
    isActive: { inputValue: null },
    activeCheckbox: { inputValue: null },
    createProvider: { inputValue: false },
  },
  createPartner: { inputValue: false },
  createBranch: { inputValue: false },
  ifProvider: null,
  isUserSuperPartner: false,
  showPasswordModal: false,
  showUpdateModal: false,
  isFormValid: true,
  formError: false,
};

class UserDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = props.update
      ? {
          ...emptyState,
          data: {
            user_groups: { inputValue: [] },
          },
          tempAccount: {
            inputValue: this.props.selectedUser.nwm_user_company[0]
              ?.nwm_user_company_x_account[0]
              ? {
                  code: this.props.selectedUser.nwm_user_company[0]
                    ?.nwm_user_company_x_account[0]?.account.id,
                  description:
                    this.props.selectedUser.nwm_user_company[0]
                      ?.nwm_user_company_x_account[0]?.account.name,
                  isAccount: true,
                }
              : '',
          },

          tempBranch: {
            inputValue: this.props.selectedUser.nwm_user_company[0]
              ?.nwm_user_company_x_branch[0]
              ? {
                  code:
                    this.props.selectedUser.nwm_user_company[0]
                      ?.nwm_user_company_x_branch[0]?.branch.id || null,
                  description:
                    this.props.selectedUser.nwm_user_company[0]
                      ?.nwm_user_company_x_branch[0]?.branch.name || null,
                  isAccount: false,
                }
              : '',
          },
          tempPermissionsAccount: {
            inputValue: props.selectedUser.nwm_user_x_role.flatMap((group) => {
              if (group.role.id !== 4)
                return {
                  code: group.role.id,
                  description: group.role[`name_${this.props.lang}`],
                };
              else return [];
            }),
          },
          tempPermissionsBranch: {
            inputValue: props.selectedUser.nwm_user_x_role.flatMap((group) => {
              if (group.role.id !== 4)
                return {
                  code: group.role.id,
                  description: group.role[`name_${this.props.lang}`],
                };
              else return [];
            }),
          },
          values: {
            isActive: { inputValue: this.props.selectedUser.is_active },
            activeCheckbox: { inputValue: !this.props.selectedUser.is_active },
            // id
            // is_demo
            first_name: { inputValue: props.selectedUser.first_name },
            last_name: { inputValue: props.selectedUser.last_name },
            email: {
              inputValue: props.selectedUser.email,
              required: true,
              validation: false,
              validator: (email) => regex.emailRegex.test(email),
            },
            // username
            // person
            partner_id: {
              inputValue: {
                code:
                  this.props.selectedUser.nwm_user_company[0]
                    ?.nwm_user_company_x_branch[0]?.branch.id ||
                  this.props.selectedUser.nwm_user_company[0]
                    ?.nwm_user_company_x_account[0]?.account.id,
                description:
                  this.props.selectedUser.nwm_user_company[0]
                    ?.nwm_user_company_x_branch[0]?.branch.name ||
                  this.props.selectedUser.nwm_user_company[0]
                    ?.nwm_user_company_x_account[0]?.account.name,
              },
            },
            partner: {
              inputValue: {
                code:
                  this.props.selectedUser.nwm_user_company[0]
                    ?.nwm_user_company_x_branch[0]?.branch.id ||
                  this.props.selectedUser.nwm_user_company[0]
                    ?.nwm_user_company_x_account[0]?.account.id,
                description:
                  this.props.selectedUser.nwm_user_company[0]
                    ?.nwm_user_company_x_branch[0]?.branch.name ||
                  this.props.selectedUser.nwm_user_company[0]
                    ?.nwm_user_company_x_account[0]?.account.name,
                isAccount: this.props.selectedUser.nwm_user_company[0]
                  ?.nwm_user_company_x_account[0]
                  ? true
                  : false,
              },
            },
            isAccount: this.props.selectedUser.nwm_user_company[0]
              ?.nwm_user_company_x_branch[0]?.branch.id
              ? false
              : this.props.selectedUser.nwm_user_company[0]
                  ?.nwm_user_company_x_account[0]?.account.id
              ? true
              : false,
            user_group: {
              inputValue:
                props.selectedUser.nwm_user_x_role.length > 0
                  ? {
                      code: props.selectedUser.nwm_user_x_role[0].role.id,
                      label: props.selectedUser.nwm_user_x_role[0].role.name,
                    }
                  : { code: -1, label: I18n.t('users.invalidUserGroup') },
            },
            language: {
              inputValue: {
                code: props.selectedUser.language.toLowerCase(),
                description: I18n.t(
                  'languages.' + props.selectedUser.language.toLowerCase(),
                ),
              },
            },
            // full name

            role: { inputValue: '' },
            password: {
              inputValue: '',
              validator: (pass) => regex.passwordRegex.test(pass),
            },
            repeatPassword: {
              inputValue: '',
              validator: (pass) =>
                pass === this.state.values.password.inputValue,
            },
            group_ids: {
              inputValue: props.selectedUser.nwm_user_x_role.map(
                (group) => group.role.id,
              ),
            },

            can_login: { inputValue: props.selectedUser.is_active },
            user_service_login: {
              inputValue: props.selectedUser.user_service_login,
            },
          },
          isUserSuperPartner:
            props.selectedUser.nwm_user_company[0] &&
            props.selectedUser.nwm_user_company[0]
              ?.nwm_user_company_x_account[0]?.account?.id,
        }
      : {
          ...emptyState,
          values: {
            ...emptyState.values,
            password: {
              inputValue: '',
              validator: (pass) => regex.passwordRegex.test(pass),
            },
            repeatPassword: {
              inputValue: '',
              validator: (pass) =>
                pass === this.state.values.password.inputValue,
            },
            email: {
              inputValue: '',
              required: true,
              validation: false,
              validator: (email) => regex.emailRegex.test(email),
            },
          },
        };
  }

  componentWillMount() {
    this.props.createUserCleanError();
    this.setState({ ifProvider: this.props.user.authProvider });
    if (this.props.user.authProvider && !this.props.user.user_admin) {
      this.setState({
        ...this.state,
        values: {
          ...this.state.values,
          createProvider: {
            ...this.state.values.createProvider,
            inputValue: this.props.user.authProvider,
          },
        },
      });
    }
  }

  componentDidMount() {
    this.props.cleanUserValidation();
    this.props.getUserGroups();
    if (
      this.props.user.partner.is_super_dashboard ||
      this.props.user.user_admin
    )
      this.props.getAllClients({
        accountDropdown: true,
        view_branch: true,
        view_account: true,
      });
    if (this.props.update) {
      if (
        this.props.selectedUser.nwm_user_company[0]
          ?.nwm_user_company_x_account[0] &&
        !this.props.user.account
      ) {
        this.setState({
          ...this.state,
          createPartner: { inputValue: true },
        });
      }
      if (
        this.props.selectedUser.nwm_user_company[0]
          ?.nwm_user_company_x_branch[0]
      ) {
        this.setState({
          ...this.state,
          createBranch: { inputValue: true },
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.update &&
      this.props.validateUserViaEmailStatus !==
        prevProps.validateUserViaEmailStatus
    ) {
      this.populateForm();
    }

    if (
      prevProps.validateUserViaEmailStatus.loading &&
      this.props.validateUserViaEmailStatus.success
    ) {
      this.populateForm();
    }
    if (
      prevProps.getUserGroupsStatus.loading &&
      this.props.getUserGroupsStatus.success
    ) {
      // available options array
      let options = [];

      const ROLES_ADMIN = [4, 6, 9, 10];
      const ROLES_BRANCH = [5, 6, 7, 8, 9, 16];
      const ROLES_ACCOUNT = [4, 6, 9, 10, 16];

      if (
        this.props.user.account?.id ||
        this.props.user.user_admin ||
        this.state.createPartner.inputValue
      ) {
        options = this.props.user_groups.filter((user) =>
          ROLES_ACCOUNT.includes(user.id),
        );
      } else {
        options = this.props.user_groups.filter((role) =>
          ROLES_BRANCH.includes(role.id),
        );
      }
      options = options.map((permission) => ({
        code: permission.id,
        description: permission.name,
      }));

      // selected user group_ids
      const groupIds = this.state.values.group_ids.inputValue;
      let groupsIdsNew = [];
      this.props.user_groups.forEach((userGroup) => {
        groupIds.some((groupId) => groupId === userGroup.id) &&
          groupsIdsNew.push({
            code: userGroup.id,
            description: userGroup.name,
          });
      });

      // commit to state
      this.setState({
        ...this.state,
        data: { user_groups: { inputValue: options } },
        values: {
          ...this.state.values,
          group_ids: {
            ...this.state.values.group_ids,
            inputValue: groupsIdsNew,
          },
        },
      });
    }
  }

  componentWillUnmount() {
    this.formReset();
  }

  formReset = () => {
    this.setState({
      ...this.state,
      values: {
        first_name: { inputValue: '', required: true, validation: false },
        last_name: { inputValue: '', required: true, validation: false },
        password: { inputValue: '', required: true, validation: false },
        repeatPassword: { inputValue: '', required: true, validation: false },
        email: { inputValue: '', required: true, validation: false },
        user_group: { inputValue: '', required: true, validation: false },
        role: { inputValue: '', required: true, validation: false },
        partner: { inputValue: '', required: true, validation: false },
        partner_id: { inputValue: -1, required: true, validation: false },
        language: { inputValue: '', required: true, validation: false },
        group_ids: { inputValue: [], required: true, validation: false },
        user_service_login: { inputValue: false },
        isActive: { inputValue: null },
        activeCheckbox: { inputValue: null },
        createProvider: { inputValue: false },
      },
      createBranch: { inputValue: false },
      createPartner: { inputValue: false },
    });
    this.props.cleanUserValidation();
  };

  passwordIsValid() {
    return (
      this.state.values.password.inputValue ===
      this.state.values.repeatPassword
        .inputValue /*  && this.state.values.user_group.inputValue.code */
    );
  }

  populateForm = () => {
    const { first_name, last_name } = this.props.userValidated;
    if (typeof this.props.userValidated === 'object') {
      this.setState({
        ...this.state,
        values: {
          ...this.state.values,
          first_name: {
            ...this.state.values.first_name,
            inputValue: first_name,
          },
          last_name: {
            ...this.state.values.last_name,
            inputValue: last_name,
          },
        },
      });
      if (
        this.props.userValidated &&
        this.props?.userValidated?.nwm_user_player[0]?.nwm_invitation[0]
          ?.campaign?.branch
      ) {
        this.setState({
          ...this.state,
          createBranch: {
            ...this.state.createBranch,
            inputValue: true,
          },
          values: {
            ...this.state.values,
            first_name: {
              ...this.state.values.first_name,
              inputValue: this.props.userValidated.first_name,
            },
            last_name: {
              ...this.state.values.last_name,
              inputValue: this.props.userValidated.last_name,
            },
            partner: {
              ...this.state.values.partner,
              inputValue: {
                code: this.props?.userValidated?.nwm_user_player[0]
                  ?.nwm_invitation[0]?.campaign?.branch.id,
                description:
                  this.props?.userValidated?.nwm_user_player[0]
                    ?.nwm_invitation[0]?.campaign?.branch.name,
              },
            },
          },
        });
      }
    }
  };

  handleInputChange = async (key, value) => {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [key]: {
          ...this.state.values[key],
          inputValue: value,
        },
      },
    });
    if (key === 'partner' || key === 'partner_id') {
      if (value.code === -1) {
        this.setState({
          ...this.state,
          values: {
            ...this.state.values,
            partner: { ...this.state.values.partner, inputValue: '' },
            group_ids: { ...this.state.values.group_ids, inputValue: [] },
          },
        });
      } else {
        if (value.isAccount) {
          this.setState({
            ...this.state,
            tempAccount: { inputValue: value },
            values: {
              ...this.state.values,
              partner: { ...this.state.values.partner, inputValue: value },
              group_ids: { ...this.state.values.group_ids, inputValue: [] },
            },
          });
        } else {
          this.setState({
            ...this.state,
            tempBranch: { inputValue: value },
            values: {
              ...this.state.values,
              partner: { ...this.state.values.partner, inputValue: value },
              group_ids: { ...this.state.values.group_ids, inputValue: [] },
            },
          });
        }
      }
    }
    if (key === 'createPartner') {
      this.setState({
        ...this.state,
        [key]: { inputValue: value },
        createBranch: { inputValue: false },
        values: {
          ...this.state.values,
          partner: { ...this.state.values.partner, inputValue: '' },
          group_ids: { ...this.state.values.group_ids, inputValue: [] },
        },
      });
      if (value) {
        this.props.getAllClients({
          accountDropdown: true,
          view_branch: false,
          view_account: true,
        });
        // if (this.state.tempAccount.inputValue !== '') {
        this.setState({
          ...this.state,
          [key]: { inputValue: value },
          createBranch: {
            inputValue: false,
          },
          values: {
            ...this.state.values,
            partner: {
              ...this.state.tempAccount,
              required: true,
              validation: false,
            },
            group_ids: {
              ...this.state.values.group_ids,
              inputValue: this.state.tempPermissionsAccount.inputValue,
            },
          },
        });
        // }
      }
    }
    if (key === 'createBranch') {
      this.setState(
        {
          ...this.state,
          [key]: { inputValue: value },
          createPartner: { inputValue: false },
          values: {
            ...this.state.values,
            partner: { ...this.state.values.partner, inputValue: '' },
            group_ids: { ...this.state.values.group_ids, inputValue: [] },
          },
        },
        () =>
          this.addOption('remove', 'group_ids', {
            code: 10,
            description: I18n.t('common.sidebar.clients'),
          }),
      );

      if (value) {
        this.props.getAllClients({
          accountDropdown: true,
          view_branch: true,
          view_account: false,
        });
        //if (this.state.tempBranch.inputValue !== '') {

        this.setState({
          ...this.state,
          [key]: { inputValue: value },
          createPartner: { inputValue: false },
          values: {
            ...this.state.values,
            partner: {
              ...this.state.values.partner,
              inputValue: this.state.tempBranch.inputValue,
            },
            group_ids: {
              ...this.state.values.group_ids,
              inputValue: this.state.tempPermissionsBranch.inputValue,
            },
          },
        });
        //}
      }
    }
    if (key === 'isActive') {
      this.setState({
        ...this.state,
        values: {
          ...this.state.values,
          [key]: { inputValue: this.state.values.activeCheckbox.inputValue },
          activeCheckbox: {
            inputValue: !this.state.values.activeCheckbox.inputValue,
          },
        },
      });
    }
    if (key === 'createProvider') {
      this.setState({
        ...this.state,
        ifProvider: !value,
        values: {
          ...this.state.values,
          [key]: { inputValue: value },
          password: { ...this.state.values.password, inputValue: '' },
          repeatPassword: {
            ...this.state.values.repeatPassword,
            inputValue: '',
          },
        },
      });
    }
  };

  _validateForm() {
    const {
      first_name,
      last_name,
      group_ids,
      email,
      password,
      repeatPassword,
      language,
      partner,
      /* role, */
    } = this.state.values;
    // se declara un flag que indica si el usuario tiene un proveedor de autenticación
    let option =
      first_name.inputValue?.length > 0 &&
      last_name.inputValue?.length > 0 &&
      group_ids.inputValue?.length &&
      regex.emailRegex.test(email.inputValue) &&
      (this.state.ifProvider
        ? (regex.passwordRegex.test(password.inputValue)
            ? password.inputValue.length > 0
            : true) &&
          (password.inputValue
            ? password.inputValue === repeatPassword.inputValue
            : true)
        : true) &&
      (language.inputValue ? true : false) &&
      ((this.state.createPartner.inputValue &&
        (partner.inputValue !== '' ||
          this.state.partner_id?.inputValue !== '')) ||
        !this.state.createPartner.inputValue);

    if (this.state.createPartner.inputValue) {
      option = option && this.state.values.partner.inputValue.isAccount;
    }
    if (this.state.createBranch.inputValue) {
      option =
        option &&
        !this.state.values.partner.inputValue.isAccount &&
        this.state.values.partner.inputValue !== '';
    }
    if (!this.props.update)
      option =
        (password.inputValue.length > 1 ||
          this.state.values.createProvider.inputValue) &&
        option;
    if (typeof this.props.userValidated === 'object') {
      option =
        group_ids.inputValue?.length &&
        regex.emailRegex.test(email.inputValue) &&
        (language.inputValue ? true : false) &&
        ((this.state.createPartner.inputValue &&
          (partner.inputValue !== '' ||
            this.state.partner_id?.inputValue !== '')) ||
          !this.state.createPartner.inputValue) &&
        ((this.state.createBranch.inputValue &&
          (partner.inputValue !== '' ||
            this.state.partner_id?.inputValue !== '')) ||
          !this.state.createBranch.inputValue);
    }
    return option;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this._validateForm()) {
      this.setState({ ...this.state, isFormValid: false });
    } else {
      let httpObject = {};
      Object.keys(this.state.values).forEach((key) => {
        httpObject[key] = Array.isArray(this.state.values[key].inputValue)
          ? this.state.values[key].inputValue.map((res) => res.code)
          : this.state.values[key].inputValue?.code
          ? this.state.values[key].inputValue.code
          : this.state.values[key].inputValue;
      });

      delete httpObject.role;
      httpObject.group = httpObject.user_group;
      if (httpObject.partner_id === -1) delete httpObject.partner_id;
      if (httpObject.partner === -1) delete httpObject.partner;
      delete httpObject.user_group;
      if (!httpObject.partner) delete httpObject.partner;
      if (
        (httpObject.partner &&
          this.state.values.partner?.inputValue.isAccount) ||
        (this.props.user.account?.id && !this.state.values.partner.inputValue)
      )
        httpObject.account_id =
          httpObject.partner || this.props.user.account?.id;
      if (
        (!this.state?.values.partner?.inputValue.isAccount &&
          this.state.createBranch.inputValue) ||
        this.props.user?.branch?.id
      )
        httpObject.branch_id =
          this.props.user.branch?.id ||
          httpObject.partner ||
          httpObject.partner_id;

      delete httpObject.partner;
      if (!httpObject.partner_id) delete httpObject.partner_id;

      httpObject['username'] = this.state.values.email.inputValue;
      httpObject.group_ids = [...httpObject.group_ids, 4]; //TO-DO revisar roles por defecto
      let error_;
      if (this.state.values.password.inputValue !== '') {
        error_ = !regex.passwordRegex.test(
          this.state.values.password.inputValue,
        )
          ? 'session.resetPassword.invalidPassword'
          : false;
        this.setState({
          ...this.state,
          formError: error_,
        });
        if (
          this.state.values.password.inputValue !==
          this.state.values.repeatPassword.inputValue
        ) {
          error_ = 'users.error.passwordDontMatch';
        }
        this.setState({
          ...this.state,
          formError: error_,
        });
      }

      const showModal =
        this.props.updateStatus.length &&
        !this.props.updateStatus.error &&
        this.props.selectedUser.id === this.props.user?.id &&
        this.props.selectedUser.email !== this.state.values?.email?.inputValue;

      if (this.props.update && !error_) {
        if (httpObject.partner_id) {
          httpObject['partner'] = httpObject['partner_id'];
          delete httpObject.partner_id;
        }
        // Para el caso del cambio de email showModal es true, entonces llamo al update email
        if (showModal) {
          this.props.updateUserEmail(this.props.selectedUser.id, httpObject);
        } else {
          this.props.updateUser(this.props.selectedUser.id, httpObject);
        }
      } else if (!showModal) {
        !error_ && this.props.createUser(httpObject);
      }

      this.setState({
        ...this.state,
        isFormValid: true,
        showUpdateModal: showModal,
        formError: error_,
      });
    }
  };

  permissionsOptions = () => {
    const isSuperPartner =
      this.props.partner && this.props.partner.is_super_dashboard;
    const superDashboardPermission = [
      ...this.state.data.user_groups.inputValue.filter(
        ({ code }) => ![5, 7, 8, 15].includes(code),
      ),
    ].map(({ code, description }) => ({
      code,
      description,
    }));

    // check if the new user is for logged in user or for partner
    if (
      this.state.createPartner.inputValue ||
      this.state.createBranch.inputValue
    ) {
      // if the user is for a Partner

      let isSuperDashboard =
        this.state.values.partner?.inputValue?.isAccount ||
        this.state.createPartner.inputValue;

      return isSuperDashboard
        ? superDashboardPermission
        : this.props.user_groups
            //.filter(({ for_partner }) => for_partner)
            .map(({ id, name }) => ({
              code: id,
              description: name,
            }))
            .filter(({ code }) => code !== 10 && code !== 15);
    } else {
      // if the user is an alias for logged in user
      return isSuperPartner || this.props.user.user_admin
        ? superDashboardPermission
        : this.state.data.user_groups.inputValue.filter(
            ({ code }) => code !== 10 && code !== 15,
          );
    }
  };
  setGroupIds = (permissions) => {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        group_ids: {
          ...this.state.values.group_ids,
          inputValue: permissions,
        },
      },
    });
  };
  addOption = (type, name, option) => {
    let newArray = [...this.state.values[name].inputValue];
    if (type === 'add') {
      newArray = newArray.concat([option]);
    } else {
      newArray = newArray.filter((opt) => opt?.code !== option?.code);
    }
    newArray.sort((profile1, profile2) => {
      return profile2.code - profile1.code;
    });
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [name]: { ...this.state.values[name], inputValue: [...newArray] },
      },
    });
    if (name === 'group_ids') {
      if (this.state.createPartner.inputValue) {
        this.setState({
          ...this.state,
          values: {
            ...this.state.values,
            [name]: { ...this.state.values[name], inputValue: [...newArray] },
          },
          tempPermissionsAccount: {
            inputValue: [...newArray],
          },
        });
      }
      if (this.state.createBranch.inputValue) {
        this.setState({
          ...this.state,
          values: {
            ...this.state.values,
            [name]: { ...this.state.values[name], inputValue: [...newArray] },
          },
          tempPermissionsBranch: {
            inputValue: [...newArray],
          },
        });
      }
    }
  };

  showTooltip = () => {
    return (
      <Tooltip translate={false} message={I18n.t('users.tooltipPassword')} />
    );
  };

  isExternalUser = () => {
    return (
      this.props.selectedUser && this.props.selectedUser.user_service_login
    );
  };

  selectedUserIsSuperPartner = () => {
    return (
      this.props.selectedUser &&
      this.props.selectedUser.nwm_user_company[0]?.nwm_user_company_x_account[0]
        ?.account
    );
  };

  modificateMyOwnUser = () => {
    return (
      this.props.selectedUser?.id === this.props.user.id && this.props.update
    );
  };
  validateUserViaEmail = async (email) => {
    let error_ = !regex.emailRegex.test(email);
    if (error_) {
      this.setState({
        ...this.state,
        formError: 'campaigns.directInvalidEmail',
      });
    } else {
      this.setState({
        ...this.state,
        formError: '',
      });
      await this.props.validateUserViaEmail({ email });
    }
  };
  render() {
    if (this.props.changePasswordStatus.success) {
      notify.show(
        I18n.t('users.success.changePassword'),
        'custom',
        5000,
        success,
      );
      this.props.clearUiValue('changePassword', 'success');
    }
    if (this.props.changePasswordStatus.error) {
      notify.show(
        I18n.t('session.resetPassword.invalidPassword'),
        'custom',
        5000,
        error,
      );
      this.props.clearUiValue('changePassword', 'error');
    }
    if (this.props.updateStatus.error) {
      notify.show(
        <SnackBar
          icon={'report'}
          title={I18n.t('common.error')}
          body={I18n.t('users.error.update')}
          type="error"
          iconFilled
        />,
        'custom',
        5000,
        errorMessage,
      );
    }
    if (this.props.createStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('users.success.create')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
    }
    const isAdmin = this.props.user.user_admin ? true : false;

    return (
      <div className={'container-fluid user-detail-screen'}>
        <div className="row">
          <ChangePasswordModal
            show={this.state.showPasswordModal}
            password={this.state.values.password}
            repeatPassword={this.state.values.repeatPassword}
            change={this.handleInputChange}
            onHide={() => {
              this.setState({ ...this.state, showPasswordModal: false });
            }}
            action={(password) => this.props.changePassword(password)}
          />
          <UpdateUserModal
            show={this.state.showUpdateModal}
            action={(e) => {
              this.handleSubmit(e);
            }}
            onHide={() => {
              this.setState({ showUpdateModal: false });
              this.props.history.push('/');
              this.props.logout();
            }}
          />
          <Tabs
            activeKey={'new-user'}
            id="user-tab"
            className="col-md-12"
            onSelect={this.handleSelect}
          >
            <Tab eventKey={'new-user'} title={I18n.t('users.identifier')}>
              <div className="card card-with-tab edit-user-container">
                <div className="card-header">
                  <div className={'card-header-left'}>
                    <div className={'back'}>
                      <Button
                        className="text-link-button back-icon"
                        iconClass={'chevron_left'}
                        iconButton={true}
                        type="button"
                        onClick={() => this.props.history.push('/main/users')}
                      />
                      {this.props.update ? (
                        <p>
                          {this.props.selectedUser.first_name +
                            ' ' +
                            this.props.selectedUser.last_name}{' '}
                          {isAdmin &&
                            this.props.selectedUser.partner_info &&
                            '- ' +
                              this.props.selectedUser.partner_info?.company}
                        </p>
                      ) : (
                        <p>{I18n.t('users.new')}</p>
                      )}
                      {!this.props.update &&
                        this.props.createStatus.error &&
                        this.props.errorCode && (
                          <div
                            className="text-warning"
                            style={{ marginLeft: '20px', paddingTop: '4px' }}
                          >
                            {I18n.t(`users.error.email`)}
                          </div>
                        )}
                      {this.props.update &&
                        this.props.updateStatus.error &&
                        this.props.errorCode && (
                          <div
                            className="text-warning"
                            style={{ marginLeft: '20px', paddingTop: '4px' }}
                          >
                            {Object.keys(this.props.errorCode)[0]
                              ? I18n.t(
                                  'users.error.' +
                                    Object.keys(this.props.errorCode)[0],
                                )
                              : I18n.t('users.error.update')}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className={'card-header-right'}>
                    {!this.state.isFormValid && (
                      <div className="text-warning">
                        {I18n.t('users.error.submit')}
                      </div>
                    )}
                  </div>
                </div>
                <form onSubmit={this.handleSubmit}>
                  {this.props.update
                    ? this.renderUpdateUserForm()
                    : this.renderNewUserForm()}
                  <div
                    className={`form-footer ${
                      this.props.userValidated !== '' ? 'validated' : ''
                    }`}
                  >
                    {this.props.update ? (
                      <Button
                        className="secondary"
                        text={I18n.t('users.update')}
                        disabled={
                          regex.passwordRegex.test(
                            this.state.values.password.inputValue,
                          ) &&
                          this.state.values.repeatPassword?.inputValue ===
                            this.state.values.password?.inputValue
                            ? false
                            : this.state.values.password?.inputValue
                            ? true
                            : false
                        }
                      />
                    ) : (
                      <Button
                        iconButton={true}
                        iconClass={'add'}
                        className=" submit primary"
                        text={I18n.t('users.createAlternative')}
                        // onClick={}
                        disabled={!this._validateForm()}
                      />
                    )}
                    <div>{this.renderLoader()}</div>
                  </div>
                </form>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }

  renderUpdateUserForm() {
    const isAdmin = this.props.user.user_admin;
    const isSuperPartner =
      this.props.partner && this.props.partner.is_super_dashboard;
    const selectedAdmin = this.props.selectedUser?.nwm_user_admin?.length
      ? true
      : false;
    const superDashboardPermission = [
      ...this.state.data.user_groups.inputValue.filter(
        ({ code }) => ![5, 7, 8, 10].includes(code),
      ),
      { code: 10, description: 'Cliente' },
    ].map(({ code, description }) =>
      code === 10 ? { code, description: 'Filiales' } : { code, description },
    );
    const externalUserService =
      this.props.selectedUser.nwm_user_company[0]?.nwm_user_company_x_account
        ?.length &&
      this.props.selectedUser.nwm_user_company[0]?.nwm_user_company_x_account[0].account?.provider?.some(
        (item) => item.type === 'AUTH',
      );
    const { loading, error } = this.props.getUserGroupsStatus;
    return (
      <div className="row">
        <div className="col-lg-3 no-padding">
          <Input
            label={I18n.t('users.first_name')}
            name="first_name"
            value={this.state.values.first_name.inputValue}
            change={this.handleInputChange}
            required={true}
          />
          <Input
            label={I18n.t('users.last_name')}
            name="last_name"
            value={this.state.values.last_name.inputValue}
            change={this.handleInputChange}
            required={true}
          />
          <Input
            placeholder={'persons.search'}
            label={I18n.t('users.email')}
            name="email"
            value={this.state.values.email}
            change={this.handleInputChange}
            disabled={this.isExternalUser()}
            required={true}
          />
          <>
            {isAdmin ? (
              <div className="flex ">
                <SquareButton
                  // disabled={this.modificateMyOwnUser()}
                  label={I18n.t('users.provider')}
                  name={'createProvider'}
                  value={this.state.values.createProvider}
                  change={this.handleInputChange}
                  setValue={!this.state.values.createProvider?.inputValue}
                />
              </div>
            ) : (
              false
            )}
          </>
        </div>

        <div className="col-lg-3 col-md-offset-1 second-col">
          {externalUserService ? (
            <div className="row container">
              <SquareButton
                label={I18n.t('users.idaptiveUser')}
                name={'user_service_login'}
                value={this.state.values.user_service_login}
                change={this.handleInputChange}
                setValue={!this.state.values.user_service_login.inputValue}
              />
            </div>
          ) : (
            false
          )}
          <div className="update-buttons">
            {!this.state.values.user_service_login?.inputValue &&
            !this.props.user?.authProvider &&
            !this.state.values.createProvider?.inputValue &&
            isAdmin ? (
              <div>
                <Input
                  label={I18n.t('users.password')}
                  name="password"
                  value={this.state.values.password}
                  change={this.handleInputChange}
                  type={'password'}
                  bottomText={{
                    appears:
                      this.state.formError ===
                      'session.resetPassword.invalidPassword'
                        ? true
                        : false,
                    message: I18n.t('users.tooltipPassword'),
                  }}
                  required={false}
                  placeholder={'users.placeholder.password'}
                  ignoreDirty
                />
                {!this.state.formError && (
                  <p className="text-on-password">
                    {I18n.t('users.tooltipPassword')}
                  </p>
                )}

                <Input
                  label={I18n.t('users.repeatPassword')}
                  name="repeatPassword"
                  value={this.state.values.repeatPassword}
                  change={this.handleInputChange}
                  type={'repeat-password'}
                  borderError={
                    this.state.values.repeatPassword?.inputValue ===
                    this.state.values.password?.inputValue
                      ? false
                      : this.state.values.password?.inputValue
                      ? true
                      : false
                  }
                  required={false}
                  placeholder={'users.placeholder.repeatPassword'}
                  password={this.state.values.repeatPassword}
                  bottomText={{
                    appears:
                      this.state.formError === 'users.error.passwordDontMatch'
                        ? true
                        : false,
                    message: I18n.t('users.error.passwordDontMatch'),
                  }}
                  ignoreDirty
                />
              </div>
            ) : (
              false
            )}
            <div className="permissions-container">
              {(isAdmin || this.props.user.account) && !selectedAdmin ? (
                <>
                  <div className="flex ">
                    {!this.props.user.account && (
                      <SquareButton
                        disabled={this.modificateMyOwnUser()}
                        label={
                          isAdmin || this.props.user.account
                            ? I18n.t('users.accountUser')
                            : I18n.t('users.filiarUser')
                        }
                        name={'createPartner'}
                        value={
                          this.state.createPartner
                            ? this.state.createPartner
                            : this.props.selectedUser.nwm_user_company
                                .nwm_user_company_x_account?.length
                            ? false
                            : true
                        }
                        change={this.handleInputChange}
                        setValue={!this.state.createPartner.inputValue}
                      />
                    )}
                    {(isAdmin || this.props.user.account) && (
                      <SquareButton
                        disabled={this.modificateMyOwnUser()}
                        label={
                          this.props.selectedUser.nwm_user_company[0]
                            .nwm_user_company_x_account.length ||
                          this.props.user.user_admin
                            ? I18n.t('users.filiarUser')
                            : I18n.t('users.accountUser')
                        }
                        name={'createBranch'}
                        value={this.state.createBranch}
                        change={this.handleInputChange}
                        setValue={!this.state.createBranch.inputValue}
                      />
                    )}
                  </div>
                </>
              ) : (
                false
              )}
              {(isAdmin || isSuperPartner) &&
              (this.state.createBranch.inputValue ||
                this.state.createPartner.inputValue) ? (
                <div>
                  <Dropdown
                    disabled={this.modificateMyOwnUser()}
                    label={
                      isAdmin
                        ? this.state.createPartner.inputValue
                          ? I18n.t('clients.accountTable')
                          : I18n.t('users.filiar')
                        : I18n.t('users.filiar')
                    }
                    name="partner"
                    value={this.state.values.partner}
                    change={this.handleInputChange}
                    options={this.props.allClients.map((client) => ({
                      code: client.id,
                      description: `${client.name}`,
                      isAccount: client.nwm_accountId ? false : true,
                    }))}
                    loading={this.props.getAllClientsStatus.loading}
                    showEmptyOption
                  />
                </div>
              ) : (
                false
              )}
              <div>
                <LanguageSelector
                  disabled={false}
                  label={I18n.t('users.idiom')}
                  onSelect={this.handleInputChange}
                  selected={
                    this.state.values.language.inputValue.code ||
                    this.state.values.language.inputValue
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 no-padding col-md-offset-1 second-col">
          {error ? (
            <div className="text-warning">
              {I18n.t('users.error.getUserGroups')}
            </div>
          ) : (
            <>
              <MultiSelect
                label={I18n.t('users.permissions')}
                value={this.state.values.group_ids}
                addOption={this.addOption}
                loading={loading}
                options={this.permissionsOptions()}
                name="group_ids"
                setValues={this.setGroupIds}
                isBranch={this.state.createBranch}
                isAdmin={this.props.selectedUser.nwm_user_admin.length > 0}
              />
            </>
          )}
          {isAdmin && (
            <div className="disable-user">
              <SquareButton
                disabled={this.modificateMyOwnUser()}
                label={I18n.t('users.disable')}
                name={'isActive'}
                value={this.state.values.activeCheckbox}
                change={this.handleInputChange}
                setValue={!this.state.values.activeCheckbox.inputValue}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  renderNewUserForm() {
    const isAdmin = this.props.user.user_admin;
    const isSuperPartner =
      this.props.partner && this.props.partner.is_super_dashboard;
    const externalUserService =
      this.props.partner && this.props.partner.external_user_service;
    const { loading, error } = this.props.getUserGroupsStatus;

    return (
      <div className="row">
        <div className="col-lg-3 no-padding">
          <div className="email-form">
            <div className="email-input">
              <Input
                label={I18n.t('users.email')}
                name="email"
                value={this.state.values.email}
                change={this.handleInputChange}
                required={true}
                placeholder={'users.placeholder.email'}
                disabled={this.props.userValidated !== '' ? true : false}
                bottomText={{
                  appears:
                    this.state.formError === 'campaigns.directInvalidEmail'
                      ? true
                      : false,
                  message: I18n.t('campaigns.directInvalidEmail'),
                }}
              />
            </div>
            <Button
              className="validate-button secondary"
              iconClass={'done'}
              iconButton={true}
              text={I18n.t('credits.validate')}
              type="button"
              onClick={async (e) => {
                e.preventDefault();
                await this.validateUserViaEmail(
                  this.state.values.email.inputValue,
                );
              }}
            />
          </div>
          <Input
            label={I18n.t('users.first_name')}
            name="first_name"
            value={this.state.values.first_name}
            change={this.handleInputChange}
            disabled={this.props.userValidated === false ? false : true}
            required={true}
            placeholder={'users.placeholder.name'}
          />
          <Input
            label={I18n.t('users.last_name')}
            name="last_name"
            value={this.state.values.last_name}
            change={this.handleInputChange}
            disabled={this.props.userValidated === false ? false : true}
            required={true}
            placeholder={'users.placeholder.lastName'}
          />

          {this.props.userValidated === '' ? (
            ''
          ) : (
            <>
              {isAdmin ? (
                <div className="flex ">
                  <SquareButton
                    // disabled={this.modificateMyOwnUser()}
                    label={I18n.t('users.provider')}
                    name={'createProvider'}
                    value={this.state.values.createProvider}
                    change={this.handleInputChange}
                    setValue={!this.state.values.createProvider?.inputValue}
                  />
                </div>
              ) : (
                false
              )}
            </>
          )}
        </div>
        <div className="col-lg-3 no-padding col-md-offset-1 second-col">
          {!this.state.values.user_service_login?.inputValue &&
          !this.props.user?.authProvider &&
          !this.state.values.createProvider?.inputValue ? (
            <div>
              <Input
                label={I18n.t('users.password')}
                name="password"
                value={this.state.values.password}
                change={this.handleInputChange}
                type={'password'}
                disabled={this.props.userValidated === false ? false : true}
                bottomText={{
                  appears:
                    this.state.formError ===
                    'session.resetPassword.invalidPassword'
                      ? true
                      : false,
                  message: I18n.t('users.tooltipPassword'),
                }}
                required={true}
                placeholder={'users.placeholder.password'}
                ignoreDirty
              />
              {!this.state.formError && (
                <p className="text-on-password">
                  {I18n.t('users.tooltipPassword')}
                </p>
              )}

              <Input
                label={I18n.t('users.repeatPassword')}
                name="repeatPassword"
                disabled={this.props.userValidated === false ? false : true}
                value={this.state.values.repeatPassword}
                change={this.handleInputChange}
                type={'repeat-password'}
                required={true}
                placeholder={'users.placeholder.repeatPassword'}
                password={this.state.values.password}
                bottomText={{
                  appears:
                    this.state.formError === 'users.error.passwordDontMatch'
                      ? true
                      : false,
                  message: I18n.t('users.error.passwordDontMatch'),
                }}
                ignoreDirty
              />
            </div>
          ) : (
            false
          )}
          {this.props.userValidated === '' ? (
            ''
          ) : (
            <>
              {isAdmin || isSuperPartner ? (
                <>
                  <div className="flex choose-partner-container">
                    {!this.props.user.account && (
                      <div className="choose-partner-item">
                        <SquareButton
                          disabled={this.modificateMyOwnUser()}
                          label={
                            isAdmin || this.props.user.account
                              ? I18n.t('users.accountUser')
                              : I18n.t('users.filiarUser')
                          }
                          name={'createPartner'}
                          value={this.state.createPartner}
                          change={this.handleInputChange}
                          setValue={!this.state.createPartner.inputValue}
                        />
                        <FormDropdown
                          disabled={
                            this.props.userValidated === '' ||
                            !this.state.createPartner.inputValue
                              ? true
                              : false
                          }
                          search={this.props.getAllClients}
                          getAllClientsPrompts={{
                            accountDropdown: true,
                            view_branch: false,
                            view_account: true,
                          }}
                          name="partner"
                          value={this.state.values.partner}
                          change={this.handleInputChange}
                          options={this.props.allClients.map((client) => ({
                            code: client.id,
                            description: `${client.name}`,
                            isAccount: client.nwm_accountId ? false : true,
                          }))}
                          loading={this.props.getAllClientsStatus.loading}
                          showEmptyOption
                        />
                      </div>
                    )}

                    {(isAdmin || this.props.user.account) && (
                      <div className="choose-partner-item">
                        <SquareButton
                          disabled={this.modificateMyOwnUser()}
                          label={I18n.t('users.filiarUser')}
                          name={'createBranch'}
                          value={this.state.createBranch}
                          change={this.handleInputChange}
                          setValue={!this.state.createBranch.inputValue}
                        />
                        <FormDropdown
                          disabled={
                            this.props.userValidated === '' ||
                            !this.state.createBranch.inputValue
                              ? true
                              : false
                          }
                          name="partner"
                          value={this.state.values.partner}
                          change={this.handleInputChange}
                          options={this.props.allClients.map((client) => ({
                            code: client.id,
                            description: `${client.name}`,
                            isAccount: client.nwm_accountId ? false : true,
                          }))}
                          loading={this.props.getAllClientsStatus.loading}
                          showEmptyOption
                          search={this.props.getAllClients}
                          getAllClientsPrompts={{
                            accountDropdown: true,
                            view_branch: true,
                            view_account: false,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                false
              )}
            </>
          )}
          {(isAdmin || isSuperPartner) && externalUserService ? (
            <div className="row container">
              <SquareButton
                label={I18n.t('users.idaptiveUser')}
                disabled={this.props.userValidated === '' ? true : false}
                name={'user_service_login'}
                value={this.state.values.user_service_login}
                change={this.handleInputChange}
                setValue={!this.state.values.user_service_login.inputValue}
              />
            </div>
          ) : (
            false
          )}
          <div className="permissions-container">
            <LanguageSelector
              disabled={this.props.userValidated === '' ? true : false}
              label={I18n.t('users.idiom')}
              onSelect={this.handleInputChange}
              selected={this.state.values.language.inputValue || 'none'}
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-offset-1">
          <div>
            {error ? (
              <div className="text-warning">
                {I18n.t('users.error.getUserGroups')}
              </div>
            ) : (
              <>
                <MultiSelect
                  label={I18n.t('users.permissions')}
                  disabled={this.props.userValidated === '' ? true : false}
                  value={this.state.values.group_ids}
                  addOption={this.addOption}
                  loading={loading}
                  options={this.permissionsOptions()}
                  name="group_ids"
                  setValues={this.setGroupIds}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
  renderLoader() {
    const { createStatus, updateStatus } = this.props;
    if (updateStatus.loading || createStatus.loading) return <Loader />;
    return null;
  }

  renderGroupOptions() {
    const { user_groups, my_groups } = this.props;

    return user_groups.map((ug) => ({ code: ug.id, description: ug.name }));
  }
}

export default UserDetailScreen;
