import React, { Component } from 'react';
import dayjs from 'dayjs';
import './CampaignsTable.css';
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import { I18n } from 'react-redux-i18n';
import Button from '../../../components/common/Button';
import Input from '../../../components/common/Input';
import DateRangePickerFilter from '../../../components/common/DateRangePickerFilter/DateRangePickerFilter';
import CreditsWidgets from '../../../components/common/CreditsWidgets/CreditsWidgets';
import Dropdown from '../../../components/common/Dropdown';
import { notify } from 'react-notify-toast';
import { success, error } from '../../../utils/modals';
import {
  errorMessage,
  successMessage,
  SnackBar,
} from '../../../utils/snackbar';
import ShareModal from '../../../components/common/ShareModal';
import Loader from '../../../components/common/Loader/Loader';
import TablePaginationDrawer from '../../../components/common/TablePaginationDrawer/TablePaginationDrawer';
import TableLoader from '../../../components/common/TableLoader/TableLoader';
import { NumberLabel } from '../../../components/common/NumberLabel/NumberLabel';
import DeleteCampaignModal from '../../../components/common/DeleteCamapignModal/DeleteCampaignModal';
import DeleteModal from '../../../components/common/ModalAlert/ModalAlert';
import { Tabs, Tab } from 'react-bootstrap';
import SearchInput from '../../../components/common/SearchInput/SearchInput';
import LargeTextTooltip from '../../../components/common/LargeTextTooltip';
import TableMenuItem from '../../../components/common/TableMenuItem/TableMenuItem';

class CampaignsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareModal: false,
      modalType: undefined,
      page: 1,
      values: {
        search:
          this.props.searchValueClean === false
            ? this.props.filterValues.search
            : '',
      },
      filtering: false,
      heightCard: 688,
      isReset: true,
    };
  }

  handleHeights(unmount = false) {
    if (!unmount)
      this.setState({
        ...this.state,
        heightCard:
          document.querySelector('.credits-card')?.offsetHeight +
          document.querySelector('.nav-tabs')?.offsetHeight,
      });
  }

  componentDidMount() {
    // window.addEventListener('load', this.handleHeights);
    // window.addEventListener('resize', () => {
    //   this.handleHeights();
    // });
    // if (document.querySelector('.credits-card')?.offsetHeight) {
    //   this.handleHeights();
    // }
    let initial_filter = {
      start_date: dayjs(
        this.props.user?.created < this.props.time_data?.start_date
          ? this.props.user?.created
          : this.props.time_data?.start_date,
      ).toISOString(),
      end_date: dayjs(this.props.time_data.end_date).toISOString(),
      ordering: '',
      search:
        this.props.searchValueClean === false
          ? this.props.filterValues.search
          : '',
    };
    this.props.updateFilterValues(initial_filter);
    this.props.getCampaigns();
    this.props.unselectCampaign();
    this.props.getCreditsHistory();
    if (this.props.searchValueClean === false) {
      this.setState(
        {
          ...this.state,
          values: {
            search: this.props.filterValues.search,
          },
        },
        () => {
          this.props.cleanSearchValue(true);
        },
      );
    }
  }

  componentWillUnmount() {
    const nextLocation = this.props.history.location.pathname;
    if (
      nextLocation !== '/main/new-campaign' &&
      nextLocation !== '/main/campaign' &&
      nextLocation !== '/main/credit-history' &&
      nextLocation !== '/'
    ) {
      this.props.updatePage(1);
    }
  }

  showModalFunc = (id) => {
    this.props.selectCampaign(id);
    this.setState({ ...this.state, shareModal: true });
  };

  handleHide = () => {
    this.setState({ ...this.state, shareModal: false });
  };

  handleCampaignDelete = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.selectCampaign(id);
    this.setState({ ...this.state, deleteModal: true });
  };

  availableCredits = () => {
    if (this.props.user_.partner) {
      return (
        this.props.user_.partner.credits -
        this.props.user_.partner.credits_assigned
      );
    }
    if (this.props.user_.branch) {
      return (
        this.props.user_.branch.credits -
        this.props.user_.branch.credits_assigned
      );
    }
  };

  filterByDates = (e) => {
    this.setState({ ...this.state, filtering: true, isReset: false });
    let date_filter = {
      start_date: dayjs(e.start_range).toISOString(),
      end_date: dayjs(e.end_range).toISOString(),
    };
    this.props.updateFilterValues(date_filter);
    this.props.getCampaigns();
  };

  handleSearch = (key, value) => {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(
      function () {
        this.props.updateFilterValues({ search: value });
        this.props.getCampaigns();
      }.bind(this),
      300,
    );
    this.setState({
      ...this.state,
      filtering: true,
      isReset: false,
      values: {
        search: value,
      },
    });
  };

  handleSelect = (e) => {
    if (e === I18n.t('creditsHistory.identifier')) {
      this.props.history.push('credits-history');
    }
  };

  onFilterReset = () => {
    let initial_filter = {
      start_date: dayjs(
        this.props.user?.created < this.props.time_data?.start_date
          ? this.props.user?.created
          : this.props.time_data?.start_date,
      ).toISOString(),
      end_date: dayjs(Date()).toISOString(),
      ordering: '',
      search: '',
    };
    this.props.updateFilterValues(initial_filter);
    this.setState({
      ...this.state,
      filtering: false,
      isReset: true,
      values: {
        search: '',
      },
    });
    this.props.getCampaigns();
  };

  render() {
    this.checkForNotifications();
    const element = document.querySelector('.credits-card');

    return (
      <div className=" campaign-campaign">
        <ShareModal
          show={this.state.shareModal}
          campaign={this.props.selectedCampaign || {}}
          onHide={this.handleHide}
          action={this.props.shareCampaign}
        />
        {/* <DeleteCampaignModal
          show={this.state.deleteModal}
          action={() =>
            this.props.deleteCampaign(this.props.selectedCampaign.id)
          }
          onHide={() => this.setState({ ...this.state, deleteModal: false })}
          campaign={
            this.props?.selectedCampaign?.name
              ? this.props.selectedCampaign.name
              : ''
          }
        /> */}
        <DeleteModal
          title={I18n.t('common.attention')}
          message={I18n.t('campaigns.deleteCampModalBody2', {
            campaign: this.props?.selectedCampaign?.name
              ? this.props.selectedCampaign.name
              : '',
          })}
          show={this.state.deleteModal}
          actionConfirm={() => {
            this.props.deleteCampaign(this.props.selectedCampaign.id);
          }}
          onHide={() => this.setState({ ...this.state, deleteModal: false })}
          actionCancel={() =>
            this.setState({ ...this.state, deleteModal: false })
          }
          textButtonConfirm={I18n.t('common.confirmDelete')}
        />

        <div className="row wrapper-row">
          <div className="col-md-10 table-side">
            <Tabs onSelect={this.handleSelect}>
              <div className={'header-left'}>
                <div className="header-left-side">
                  {/* <div className={'search-bar'}>
                    <span class="material-symbols-rounded search-icon">
                      search
                    </span>
                    <Input
                      name="search"
                      value={this.state.values.search}
                      placeholder={I18n.t('persons.search')}
                      change={this.handleSearch}
                    />
                  </div> */}
                  <SearchInput
                    name="search"
                    placeholder={I18n.t('persons.search')}
                    value={this.state.values.search}
                    displayValue={this.state.values.search}
                    onChange={(e) =>
                      this.handleSearch(e.target.name, e.target.value)
                    }
                  />
                  <div className="date-range-selector">
                    <DateRangePickerFilter
                      start_date={this.props.time_data.start_date}
                      end_date={this.props.time_data.end_date}
                      classNameButton="date-person"
                      filter={this.filterByDates}
                      resetFilter={this.state.isReset}
                      hiddenPopup={true}
                      filtering={this.state.filtering}
                      {...this.props}
                    />
                  </div>
                </div>
                <div className="filter-clear-button">
                  <Button
                    className="filter-button-client"
                    iconButton
                    iconClass={'close'}
                    text={I18n.t('buttons.clearFilter')}
                    onClick={() => {
                      this.onFilterReset();
                    }}
                    disabled={!this.state.filtering}
                  />
                </div>
              </div>

              <Tab
                eventKey={I18n.t('campaigns.campaigns')}
                title={I18n.t('campaigns.campaigns')}
              >
                {this.renderTable()}
              </Tab>
              <Tab
                eventKey={I18n.t('creditsHistory.identifier')}
                title={I18n.t('creditsHistory.identifier')}
              ></Tab>
            </Tabs>
          </div>

          <div className="col-md-2 credits-side">
            <div className="card-header">
              <Button
                text={I18n.t('campaigns.new')}
                iconButton
                iconClass="add"
                className="primary"
                onClick={() => {
                  this.props.unselectCampaign();
                  this.props.history.push('new-campaign');
                }}
              />
            </div>
            {!this.props.getCampaignsStatus.loading && (
              <div className="campaign-credits">
                <div className="credits-body">
                  <div className="credits-card" id="credits-card">
                    <div className="col total-credits">
                      <div className="database">
                        <span className="material-symbols-rounded material-symbols-filled">
                          database
                        </span>
                        {I18n.t('credits.totalCredits')}
                      </div>
                      <div className="totalcredits-text">
                        <span className="text">
                          {I18n.t('credits.totalCreditsCredits')}
                        </span>
                        <span className="number">
                          {this.props.user_?.branch.credits ||
                            this.props.user_?.partner.credits}
                        </span>
                        <span className="text">
                          {I18n.t('credits.totalCreditsDescription')}
                        </span>
                      </div>
                    </div>

                    <div className="col">
                      <CreditsWidgets
                        material_symbol={'priority'}
                        title={I18n.t('credits.available')}
                        label={I18n.t('credits.availableCredits')}
                        value={this.availableCredits()}
                        description={
                          this.props.user_.account !== null
                            ? I18n.t('credits.availableCreditsDescription')
                            : this.props.user_.partner.is_super_dashboard
                            ? I18n.t(
                                'credits.availableCreditsDescriptionFilial',
                              )
                            : I18n.t('credits.availableCreditsCampaign')
                        }
                        type={'column-view'}
                        renderStyle="available"
                      />
                    </div>
                    <div className="col">
                      <CreditsWidgets
                        material_symbol={
                          this.props.user_.account !== null
                            ? 'account_tree'
                            : 'campaign'
                        }
                        title={I18n.t('credits.associated')}
                        label={I18n.t('credits.associatedCreditsDetail')}
                        value={
                          this.props.user_.partner.credits_assigned ||
                          this.props.user_.branch.credits_assigned
                        }
                        description={
                          this.props.user_.account !== null
                            ? I18n.t('credits.associatedCreditsDescription')
                            : I18n.t(
                                'credits.associatedCreditsDescriptionFilial',
                              )
                        }
                        type={'column-view'}
                        renderStyle="associated"
                      />
                    </div>
                    {this.props.user_.branch ? (
                      <div className="col">
                        <CreditsWidgets
                          material_symbol={'groups'}
                          title={I18n.t('credits.assigned')}
                          label={I18n.t('credits.assignedCredits')}
                          value={
                            this.props.credits_user_status?._sum
                              ?.credits_assigned
                          }
                          description={I18n.t(
                            'credits.assignedCreditsDescription',
                          )}
                          type={'column-view'}
                          renderStyle={'actives'}
                        />
                      </div>
                    ) : null}

                    <div className="col">
                      <CreditsWidgets
                        material_symbol={'sports_esports'}
                        title={I18n.t('credits.consumed')}
                        label={I18n.t('credits.creditsConsumed')}
                        value={
                          this.props.credits_user_status?._sum?.credits_consumed
                        }
                        description={I18n.t('credits.lowCaseCredits')}
                        type={'column-view'}
                        renderStyle={'consumed'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderTable() {
    const { getCampaignsStatus, campaignDeleteStatus } = this.props;
    const loading = getCampaignsStatus.loading || campaignDeleteStatus.loading;
    const error = getCampaignsStatus.error || campaignDeleteStatus.error;
    const success = getCampaignsStatus.success || campaignDeleteStatus.success;
    if (error) {
      if (getCampaignsStatus.error)
        return (
          <div className="text-warning">{I18n.t('campaigns.error.get')}</div>
        );
      if (campaignDeleteStatus.error)
        return (
          <div className="text-warning">{I18n.t('campaigns.error.delete')}</div>
        );
    } else if (loading || success) {
      return (
        <div className="table">
          {this.props.campaigns.map((c) => (
            <>
              <LargeTextTooltip
                id={'name-campaign-table-column' + c.id}
                idToOverflow={'campaign-name-' + c.id}
              />
              <LargeTextTooltip
                id={'job-column-' + c.id}
                idToOverflow={'job-column' + c.id}
              />
              <LargeTextTooltip
                id={'creator-column-' + c.id}
                idToOverflow={'creator-column' + c.id}
              />
            </>
          ))}

          <ReactTable
            columns={[
              {
                Header: (
                  <span>
                    <span>{I18n.t('campaigns.name')}</span>
                    <span class="material-symbols-rounded">
                      arrow_drop_down
                    </span>
                  </span>
                ),
                headerClassName: 'wordwrap',
                id: 'name',
                accessor: (d) => d,
                Cell: (props) => (
                  <div className="name-field">
                    <div className="name-field">
                      <span
                        className="number"
                        id={'campaign-name-' + props.value.id}
                        data-tooltip-content={props.value.name}
                        data-tooltip-id={
                          'name-campaign-table-column' + props.value.id
                        }
                      >
                        {props.value.name}
                      </span>

                      <span class="campaign-code">
                        {props.value.code?.length === 12
                          ? `${props.value.code.substring(
                              0,
                              4,
                            )}-${props.value.code.substring(
                              4,
                              8,
                            )}-${props.value.code.substring(8, 12)}`
                          : props.value.code?.length === 9
                          ? `${props.value.code.substring(
                              0,
                              3,
                            )}-${props.value.code.substring(
                              3,
                              6,
                            )}-${props.value.code.substring(6, 9)}`
                          : '-'}
                      </span>
                    </div>
                  </div>
                ),
              },
              {
                Header: (
                  <span class="position-field">
                    <span>{I18n.t('jobs.job')}</span>
                    <span class="material-symbols-rounded">
                      arrow_drop_down
                    </span>
                  </span>
                ),
                headerClassName: 'wordwrap',
                id: 'position',
                accessor: (d) => d,
                Cell: (props) => {
                  return (
                    <span
                      class="position-field"
                      id={'job-column' + props.original.id}
                      data-tooltip-content={
                        props.original.position?.name || '-'
                      }
                      data-tooltip-id={'job-column-' + props.original.id}
                    >
                      {props.original.position?.name || '-'}
                    </span>
                  );
                },
              },
              {
                Header: (
                  <span class="centered-values">
                    <span>{I18n.t('campaigns.codes_associated')}</span>
                    <span class="material-symbols-rounded">
                      arrow_drop_down
                    </span>
                  </span>
                ),
                headerClassName: 'wordwrap codes_associated',
                id: 'credits_associated',
                accessor: (d) => d,
                Cell: (props) => (
                  <span class="centered-values associated">
                    {props.value.credits ? (
                      <NumberLabel value={props.value.credits} />
                    ) : (
                      '0'
                    )}
                  </span>
                ),
              },
              {
                Header: (
                  <span class="centered-values">
                    <span>{I18n.t('campaigns.codes_available')}</span>
                    <span class="material-symbols-rounded">
                      arrow_drop_down
                    </span>
                  </span>
                ),
                headerClassName: 'wordwrap codes_available',
                id: 'credits_available',
                accessor: (d) => d,
                Cell: (props) => (
                  <span class="centered-values available">
                    {props.value.credits && (
                      <NumberLabel
                        value={
                          props.value.credits - props.value.credits_assigned
                        }
                      />
                    )}
                  </span>
                ),
              },
              {
                Header: (
                  <span class="centered-values">
                    <span>{I18n.t('campaigns.codes_assigned')}</span>
                    <span class="material-symbols-rounded">
                      arrow_drop_down
                    </span>
                  </span>
                ),
                headerClassName: 'wordwrap codes_assigned',
                id: 'credits_assigned',
                accessor: (d) => d,
                Cell: (props) => (
                  <span class="centered-values assigned">
                    {props.value.credits_assigned ? (
                      <NumberLabel value={props.value.credits_assigned} />
                    ) : (
                      '0'
                    )}
                  </span>
                ),
              },
              {
                Header: (
                  <span class="centered-values">
                    <span>{I18n.t('campaigns.codes_consumed')}</span>
                    <span class="material-symbols-rounded">
                      arrow_drop_down
                    </span>
                  </span>
                ),
                headerClassName: 'wordwrap codes_consumed',
                id: 'credits_consumed',
                accessor: (d) => d,
                Cell: (props) => (
                  <span class="number centered-values consumed">
                    {props.value.credits_consumed || '0'}
                  </span>
                ),
              },
              {
                Header: (
                  <span class="date-field">
                    <span>{I18n.t('dropdown.date')}</span>
                    <span class="material-symbols-rounded">
                      arrow_drop_down
                    </span>
                  </span>
                ),
                headerClassName: 'wordwrap',
                id: 'created',
                accessor: (d) => d,
                Cell: (props) => (
                  <span class="number date-field">
                    {dayjs(props.value.created).format('DD/MM/YYYY')}
                  </span>
                ),
              },
              {
                Header: (
                  <span class="created-by-field">
                    <span>{I18n.t('campaigns.created_by')}</span>
                    <span class="material-symbols-rounded">
                      arrow_drop_down
                    </span>
                  </span>
                ),
                headerClassName: 'wordwrap',
                id: 'user_name',
                accessor: (d) => d,
                Cell: (props) => (
                  <span
                    className="creator-name"
                    data-tooltip-content={
                      props.value.user_company?.user.full_name || '-'
                    }
                    id={'creator-column' + props.value.id}
                    data-tooltip-id={'creator-column-' + props.value.id}
                  >
                    {props.value.user_company?.user.full_name || '-'}
                  </span>
                ),
              },

              {
                Header: <span></span>,
                headerClassName: 'wordwrap',
                id: 'share',
                accessor: (d) => d,
                sortable: false,
                width: 97,
                Cell: (props) => {
                  const { campaign_type, id, created } = props.value;
                  return (
                    <TableMenuItem>
                      <div className="share-row">
                        <Dropdown
                          value={{
                            inputValue: {
                              code: '1',
                              label: (
                                <span class="material-symbols-rounded">
                                  more_vert
                                </span>
                              ),
                            },
                          }}
                          className="users-dropdown"
                          noBorder
                          // change={() => this.handleActions(props.value)}
                          options={[
                            {
                              code: 'share',
                              label: (
                                <Button
                                  className={'bg-primary text-white p-0'}
                                  iconButton={true}
                                  iconClass={'edit'}
                                  iconFilled
                                  text={I18n.t('campaigns.edit')}
                                  disabled={!created}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.props.selectCampaign(id);
                                    this.props.history.push(
                                      '/main/edit-campaign',
                                    );
                                    /* if (campaign_type === 'DIRECT') {
                                    this.props.history.push('/main/editcampaign');
                                  } else this.showModalFunc(id); */
                                  }}
                                />
                              ),
                              disabled: false,
                            },
                            {
                              code: 'delete',
                              label: (
                                <Button
                                  className={'bg-warning text-white p-0'}
                                  iconButton={true}
                                  iconClass={'delete'}
                                  iconFilled
                                  text={I18n.t('common.delete')}
                                  disabled={props.value.credits_consumed > 0}
                                  onClick={(e) =>
                                    this.handleCampaignDelete(e, id)
                                  }
                                />
                              ),
                              disabled: false,
                            },
                          ]}
                        />
                      </div>
                    </TableMenuItem>
                  );
                },
                getProps: () => ({
                  onClick: (e) => e.stopPropagation(),
                  style: { cursor: 'default' },
                }),
              },
            ]}
            data={this.props.campaigns}
            manual
            minRows={0}
            noDataText={I18n.t('campaigns.table.noRows')}
            resizable={false}
            // TODO: ReactTable sorting
            onSortedChange={(newSorted, column, shiftKey) => {
              let filter = column.id;
              this.props.updateFilterValues({
                ordering: (newSorted[0].desc ? '-' : '') + filter,
              });
              this.props.getCampaigns();
            }}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  if (handleOriginal) {
                    handleOriginal();
                  }
                  this.props.selectCampaign(rowInfo.original.id);
                  this.props.history.push('/main/campaign');
                },
                style: {
                  cursor: 'pointer',
                },
              };
            }}
            manualPagination
            pageSize={this.props.pageSize}
            PaginationComponent={() => {
              const {
                currentPage,
                updatePage,
                updatePageSize,
                totalPages,
                pageSize,
                totalCount,
              } = this.props;

              const isOnFirstPage = currentPage === 1;
              const isOnLastPage = currentPage === totalPages;
              return (
                <TablePaginationDrawer
                  data={{
                    totalCount,
                    currentPage,
                    pageSize,
                    isOnFirstPage,
                    isOnLastPage,
                    updatePage,
                    updatePageSize,
                    totalPages,
                  }}
                  table={'campaigns'}
                />
              );
            }}
            // defaultSorted={[{
            //     // get first column id, will be same as asigned
            //     // in react-table
            //     id: (columns.length > 0) ? columns[0].code : null,
            // }]}
            loading={loading}
            LoadingComponent={TableLoader}
          />
        </div>
      );
    } else return <Loader />;
  }

  checkForNotifications = () => {
    if (this.props.getCampaignsStatus.error) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('creditsHistory.table.error')}
          body={I18n.t('campaigns.error.get')}
          type="error"
          iconFilled
        />,
        'custom',
        5000,
        errorMessage,
      );

      this.props.clearUiValue('getCampaignsStatus ', 'error');
    }

    if (this.props.createStatus.error) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('creditsHistory.table.error')}
          body={I18n.t('campaigns.error.create')}
          type="error"
          iconFilled
        />,
        'custom',
        5000,
        errorMessage,
      );

      this.props.clearUiValue('createStatus', 'error');
    }

    if (this.props.campaignDeleteStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('campaigns.success.delete')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );

      this.props.clearUiValue('campaignDeleteStatus', 'success');
    }

    if (this.props.campaignDeleteStatus.error) {
      let message = I18n.t('campaigns.error.delete');
      if (
        this.props.campaignDeleteStatus.errorDetailsDeleting ===
        'deleteErrorByUserPlaying'
      ) {
        message = I18n.t(
          'campaigns.error.' +
            this.props.campaignDeleteStatus.errorDetailsDeleting,
        );
      }
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('creditsHistory.table.error')}
          body={message}
          type="error"
          iconFilled
        />,
        'custom',
        5000,
        errorMessage,
      );
      this.props.clearUiValue('campaignDeleteStatus', 'error');
      return;
    }

    if (this.props.updateStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('campaigns.success.update')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );

      this.props.clearUiValue('updateStatus', 'success');
    }

    if (this.props.shareStatus.error) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('creditsHistory.table.error')}
          body={I18n.t('campaigns.error.share')}
          type="error"
          iconFilled
        />,
        'custom',
        5000,
        errorMessage,
      );

      this.props.clearUiValue('shareStatus', 'error');
    }

    if (this.props.shareStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('campaigns.success.share')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );

      this.props.clearUiValue('shareStatus', 'success');
    }
    return;
  };
}

export default CampaignsTable;
