import React from 'react';
import './PlayerDetail.sass';
import LargeTextTooltip from '../LargeTextTooltip';
import { formatedCode } from '../../../utils/functions';

function PlayerDetail({ index, email, largeText, code, code_ie }) {
  return (
    <div
      className="player-detail-container"
      key={`email-not-consumed-${index}`}
    >
      <p
        id={`email-not-consumed-${index}`}
        data-tooltip-content={email}
        data-tooltip-id={`email-not-consumed-tooltip-${index}`}
      >
        {email}{' '}
      </p>
      <LargeTextTooltip
        id={`email-not-consumed-tooltip-${index}`}
        idToOverflow={`email-not-consumed-${index}`}
        key={`email-not-consumed-${largeText}`}
      />
      <div class="group-code">
        <div class="detail-code">
          <span className="material-symbols-filled icon">psychology</span>
          <span>{formatedCode(code)}</span>
        </div>
        {code_ie && (
          <div class="detail-code">
            <span className="material-symbols-filled icon">diagnosis</span>
            <span>{formatedCode(code_ie)}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default PlayerDetail;
