import React, { Component } from 'react';

import './LargeTextTooltip.css';
import { Tooltip } from 'react-tooltip';

class LargeTextTooltip extends Component {
  render() {
    const { id, idToOverflow } = this.props;
    const renderTooltip =
      document.querySelector('#' + idToOverflow)?.offsetWidth <
      document.querySelector('#' + idToOverflow)?.scrollWidth;

    if (renderTooltip || !idToOverflow)
      return <Tooltip id={id} place="top" effect="solid" />;
    else return <></>;
  }
}

export default LargeTextTooltip;
