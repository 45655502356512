import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import './Input.css';
import show from '../../../assets/icons/ICON-password-show.svg';
import hide from '../../../assets/icons/ICON-password-hide.svg';
import ReactSlider from 'react-slider';

class Input extends Component {
  static defaultProps;

  state = {
    borderError: false,
    showError: false,
    dirty: false,
    showPassword: false,
    passwordChecked: false,
    focus: false,
    inputValue: '',
  };

  componentWillUnmount() {
    this.setState({
      borderError: false,
      showError: false,
      dirty: false,
      showPassword: false,
      passwordChecked: false,
    });
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      inputValue: this.props.value.inputValue,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorLogin !== this.props.errorLogin) {
      this.handleBlur();
    }
    if (prevProps.borderError !== this.props.borderError) {
      const { value, ignoreDirty } = this.props;
      this.setState({
        borderError:
          value.validator &&
          !value.validator(this.props.value.inputValue) &&
          (this.state.dirty || ignoreDirty),
      });
    }
    if (prevProps.clearInput !== this.props.clearInput) {
      this.setState({
        ...this.state,
        inputValue: '',
      });
    }
  }

  handleBlur = () => {
    const { value } = this.props;
    this.setState({
      borderError:
        (value.inputValue === '' || !value.inputValue) &&
        (value.required || this.props.required),
      showError: value.validator && !value.validator(value.inputValue),
      dirty: true,
      focus: false,
    });
  };

  handleChange = (event) => {
    const { type, value, name, ignoreDirty } = this.props;
    // El ultimo chequeo se agrego porque sino no se podia vaciar el campo
    let newValue = '';
    if (type === 'number') {
      newValue = parseFloat(event.target.value.replace(/,|\./g, ''));
    } else if (type === 'hidden-value') {
      newValue = this.getHiddenValue(event);
    } else {
      newValue = event.target.value;
    }

    if (name === 'email' || name === 'username' || name === 'codeEmail')
      newValue = newValue.replace(/\s/g, '');

    this.props.change(name, newValue);
    this.setState({
      showError:
        value.validator &&
        !value.validator(newValue) &&
        (this.state.dirty || ignoreDirty),
      inputValue: newValue,
    });
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  getHiddenValue(event) {
    const { propsValue } = this.props;
    const value = event.target.value;
    if (!propsValue.inputValue) return value;
    if (value.length - propsValue.inputValue.length === 1)
      return (propsValue.inputValue || '') + '' + value[value.length - 1];
    if (value.length - propsValue.inputValue.length === -1)
      return propsValue.inputValue.slice(0, propsValue.inputValue.length - 1);
  }

  handleShowPassword = () => {
    this.setState({
      ...this.state,
      showPassword: !this.state.showPassword,
      //borderError: !this.props.checkingPassword,
    });
  };

  formatNumber(value) {
    let string = value?.toString();
    if (string.length > 16) string = string.slice(0, 17); // limitate number to 16 digits
    const number = parseFloat(string.replace(/,|\./g, ''));
    if (isNaN(number)) return '';
    else return number.toLocaleString('es-AR', { maximumFractionDigits: 0 });
  }

  render() {
    const {
      key,
      label,
      value,
      disabled,
      hideOptional,
      slider,
      placeholder,
      change,
      noTranslation,
      autocomplete,
      type,
      textarea,
      autoFocus,
      name,
      maxLength,
      min,
      tooltip,
      bottomText,
      required,
      iconInput,
      iconClass,
      checkingPassword,
      emailChecker,
    } = this.props;
    return (
      <div className={'input' + (disabled ? ' disabled' : '')} key={key}>
        <div
          className={`${
            tooltip
              ? 'input-label-container with-tooltip'
              : 'input-label-container'
          }`}
        >
          {label && (
            <label
              className={
                'input-label ' +
                (value.required ? 'required' : null) +
                (disabled ? ' disabled' : '')
              }
            >
              {noTranslation ? (
                <span>{label}</span>
              ) : (
                <Translate value={label} />
              )}
              {required && <span className="required-icon">*</span>}
              {tooltip && tooltip}
            </label>
          )}
          {!value.required && !hideOptional && (
            <label className="optional-text">
              <Translate value="common.optional" />
            </label>
          )}
        </div>
        {textarea === true ? (
          <textarea
            rows={'3'}
            disabled={disabled}
            placeholder={I18n.t(placeholder)}
            value={value.inputValue}
            onChange={(event) => change(name, event.target.value)}
          />
        ) : slider ? (
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="thumb"
            trackClassName="track"
            renderThumb={(props, state) => <div {...props} />}
          />
        ) : (
          <div className="input-holder">
            {iconInput && (
              <span className="material-symbols-rounded">{iconClass}</span>
            )}
            <input
              className={
                ((this.state.borderError ||
                  this.state.showError ||
                  this.props.bottomText?.appears) &&
                ((this.state.dirty && !this.state.focus) ||
                  this.props.ignoreDirty)
                  ? 'border-error'
                  : '') +
                ((value.inputValue || value.inputValue?.length) &&
                !this.state.borderError &&
                !this.state.showError &&
                !this.props.bottomText?.appears &&
                ((this.state.dirty && !this.state.focus) ||
                  this.props.ignoreDirty)
                  ? ' border-success'
                  : '') +
                (this.props.value?.inputValue !== '' &&
                !(
                  (this.state.borderError ||
                    this.state.showError ||
                    this.props.bottomText?.appears) &&
                  ((this.state.dirty && !this.state.focus) ||
                    this.props.ignoreDirty)
                )
                  ? this.props.type === 'filter'
                    ? ' active'
                    : ' border-success'
                  : '')
              }
              onBlur={this.handleBlur}
              onFocus={() => {
                this.setState({ ...this.state, focus: true });
              }}
              disabled={disabled}
              min={min || null}
              maxLength={maxLength || null}
              autoComplete={
                autocomplete
                  ? autocomplete
                  : `${name === 'search' ? 'off' : null}`
              }
              placeholder={I18n.t(placeholder)}
              id="inputId"
              type={
                this.state.showPassword
                  ? 'text'
                  : type === 'register-password' || type === 'repeat-password'
                  ? 'password'
                  : type && type !== 'number'
                  ? type
                  : 'text'
              }
              value={
                type === 'number'
                  ? this.formatNumber(value.inputValue)
                  : type === 'hidden-value' &&
                    value.inputValue &&
                    !this.state.showPassword
                  ? value.inputValue
                      .split('')
                      .map((d) => '*')
                      .join('')
                  : name === 'codeEmail' &&
                    this.state.inputValue !== value.inputValue
                  ? value.inputValue
                  : value.inputValue
                  ? value.inputValue
                  : this.state.inputValue
              }
              onChange={this.handleChange}
              onKeyDown={
                name === 'email' || name === 'username'
                  ? this.handleKeyDown
                  : null
              }
              autoFocus={autoFocus}
            />
            {label === I18n.t('login.newpassword') ? (
              <p className="text-on-password">
                {I18n.t('users.tooltipPassword')}
              </p>
            ) : null}

            {(name === 'register-password' ||
              name === 'password' ||
              name === 'repeatPassword' ||
              name === 'currentPassword' ||
              name === 'new_password' ||
              name === 're_new_password' ||
              type === 'hidden-value') && (
              <button
                type="button"
                className="show-password"
                onClick={this.handleShowPassword}
                disabled={this.props.disabled}
              >
                <span className="material-symbols-filled">
                  {!this.state.showPassword ? 'visibility_off' : 'visibility'}
                </span>
              </button>
            )}
          </div>
        )}
        {bottomText?.appears && (
          <label className={'bottom-text-label danger'}>
            {bottomText?.message}
          </label>
        )}
      </div>
    );
  }
}

Input.defaultProps = {
  label: '',
  placeholder: '',
};
Input.propTypes = {
  value: PropTypes.object || PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  change: PropTypes.func,
  clearInput: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  hideOptional: PropTypes.bool,
  disabled: PropTypes.bool,
  slider: PropTypes.bool,
  noTranslation: PropTypes.bool,
  onForgotPassword: PropTypes.func,
  autocomplete: PropTypes.string,
  maxLength: PropTypes.string,
  emailChecker: PropTypes.bool,
};

export default Input;
