import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import './FormDropdown.sass';
import dropdownIcon from '../../../assets/icons/down-arrow-LIGHT-24x24.png';
import Loader from '../Loader';
import Input from '../Input';
/**
 * Documentation
 * ------------------------
 * @description: <button/> with <ul><li>OPTIONS</li></ul>
 * @type:
 * 		1. single option select (required, no empty option)
 * 		2. single option select (not required, empty option selectable)
 * 		3. multiSelect
 *
 * (1, 2): 	showEmptyOption prop adds to option array the placeholder
 * 			or generic option.
 * (3): 	prop renders other components, and needs an addOption
 * 			prop function call.
 */
class FormDropdown extends Component {
  static defaultProps;

  state = {
    showOptions: false,
    filterValue: '',
    search: '',
    clearInputValue: false,
  };

  handleHideOptionsState = (ev) => {
    if (
      ev.path?.filter((element) => element.id === this.props.name).length === 0
    ) {
      this.setState({ ...this.state, showOptions: false });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.clearInput !== this.props.clearInput) {
      this.props.search(
        this.props.getAllClientsPrompts
          ? { ...this.props.getAllClientsPrompts, search: '' }
          : { search: '' },
      );
    }

    if (prevState.clearInputValue === true) {
      this.setState({
        ...this.state,
        clearInputValue: false,
      });
    }

    if (prevState.clearInputValue !== this.state.clearInputValue) {
      this.setState({
        ...this.state,
        filterValue: '',
        search: '',
      });
      this.props.search(
        this.props.getAllClientsPrompts
          ? { ...this.props.getAllClientsPrompts, search: '' }
          : { search: '' },
      );
    }
  }

  handleSelection(e, option) {
    if (this.props.multiSelect) {
      if (this.optionIsSelected(option)) {
        this.props.addOption('remove', this.props.name, option);
      } else {
        this.props.addOption('add', this.props.name, option);
        this.props.oneSelect &&
          this.setState({ ...this.state, showOptions: false });
      }
    } else {
      this.props.change(this.props.name, option);
    }
  }

  optionIsSelected = (option) => {
    return this.props.value.inputValue?.find(
      (currentOption) => currentOption.code === option.code,
    );
  };

  removeDuplicates(originalArray, objKey) {
    let trimmedArray = [];
    let values = [];
    let value;

    for (let i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];
      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }
    return trimmedArray;
  }

  filterAndOrder(array) {
    const { removeDuplicates, filter, order } = this.props;
    let result = array;
    result = removeDuplicates
      ? this.removeDuplicates(result, 'description')
      : result;
    if (filter && this.state.filterValue) {
      result = array.filter(
        (option) =>
          (option.label || option.description) &&
          (option.label || option.description)
            .toLowerCase()
            .includes(this.state.filterValue.toLowerCase()),
      );
    }
    if (order) {
      result = result.sort(function (a, b) {
        return a.description || a.label
          ? (a.description || a.label).localeCompare(b.description || b.label)
          : 0;
      });
    }
    return result;
  }
  handleSearch = (key, value) => {
    if (this.searchTimeout) clearTimeout(this.searchTimeout);
    if (typeof this.props.search !== 'string') {
      this.searchTimeout = setTimeout(
        function () {
          this.props.search(
            this.props.getAllClientsPrompts
              ? { ...this.props.getAllClientsPrompts, search: value }
              : { search: value },
          );
        }.bind(this),
        300,
      );
    }
  };

  render() {
    const {
      name, // id
      value,
      label,
      multiSelect,
      navbarFont,
      disabled,
      filter,
      translate, // apply I18n.t(...)
      placeholder,
      showEmptyOption, // dropdown value can be null
      noBorder, // style
      loading,
      cleanOption,
      gray,
      className, // style gray border
      search,
      bottomText,
    } = this.props;

    let options = [];
    if (showEmptyOption) {
      if (placeholder)
        options = options.concat({ code: -1, description: placeholder });
      else
        options = options.concat({
          code: -1,
          description: I18n.t('dropdown.default'),
        });
    }
    options = options.concat(this.props.options);
    return (
      <div
        className={`dropdown-container ${className}`}
        id={name}
        onClick={() =>
          multiSelect &&
          document.addEventListener('mousedown', this.handleHideOptionsState)
        }
      >
        {label && (
          <label
            className={
              'input-label ' +
              (disabled ? ' disabled' : '') +
              (value.required ? ' required' : '')
            }
          >
            <Translate value={label} />
          </label>
        )}
        <div
          className={`form-dropdown ${
            disabled
              ? `disabled`
              : ` ${bottomText?.appears ? 'border-error' : ''}`
          } ${value.inputValue?.length ? ' selected ' : ''} ${
            value.inputValue?.code &&
            value.inputValue?.description !== I18n.t('clients.filiar')
          } ${
            value.inputValue?.code &&
            value.inputValue?.description !== I18n.t('clients.accountButton')
              ? ' selected '
              : ''
          }`}
        >
          {multiSelect
            ? [
                <button
                  className={
                    (disabled ? ' disabled' : '') +
                    (navbarFont ? ' navbar-font' : '') +
                    (noBorder ? ' no-border' : '') +
                    (value.inputValue?.code ? ' selected ' : '') +
                    (this.props.class || '') +
                    (loading ? ' loading' : '') +
                    (gray ? ' gray' : '')
                  }
                  type="button"
                  data-toggle="dropdown"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      showOptions: !this.state.showOptions,
                    });
                  }}
                  key={`dropdown-multi-select`}
                >
                  {!disabled && this.renderMultiSelectButtonContent()}
                </button>,

                <ul
                  className={`dropdown-menu ${
                    this.props.search && 'with-search'
                  }`}
                  key="dropdown-menu"
                >
                  {filter && (
                    <input
                      className={'filter-input'}
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          filterValue: e.target.value,
                        });
                      }}
                      placeholder={'Filter'}
                    />
                  )}
                  {search && (
                    <div className={'search-bar'}>
                      <i className={'fa fa-search'} />
                      <Input
                        name="search"
                        clearInput={
                          this.props.clearInput || this.state.clearInputValue
                        }
                        value={this.state.search}
                        placeholder={I18n.t('persons.search')}
                        change={this.handleSearch}
                      />
                    </div>
                  )}
                  {this.props.cleanOption && (
                    <li
                      onClick={(e) => {
                        this.props.cleanSelected();
                        this.setState({
                          ...this.state,
                          search: '',
                          clearInputValue: true,
                        });
                      }}
                      className="clean-filter"
                    >
                      {this.props.textCleanOption ||
                        I18n.t('dropdown.cleanFilter')}
                    </li>
                  )}
                  {!loading &&
                    this.filterAndOrder(
                      options?.filter((item) => item.code !== -1),
                    ).map((option, i) => (
                      <li
                        className={`${option.subitem ? 'sub-item' : ''} ${
                          option.disabled ? 'disabled' : ''
                        } ${
                          multiSelect && this.optionIsSelected(option)
                            ? 'selected'
                            : ''
                        }`}
                        key={option.code + '-' + i}
                        onClick={(e) =>
                          !option.disabled && this.handleSelection(e, option)
                        }
                      >
                        {translate
                          ? I18n.t(option.description || option.label)
                          : option.description || option.label}
                        {option.menu && <img src={dropdownIcon} alt="" />}
                      </li>
                    ))}
                </ul>,
              ]
            : [
                <button
                  className={
                    (disabled ? ' disabled' : '') +
                    (navbarFont ? ' navbar-font' : '') +
                    (noBorder ? ' no-border' : '') +
                    (value.inputValue?.code ? ' selected ' : '') +
                    (this.props.class || '') +
                    (loading ? ' loading' : '') +
                    (gray ? ' gray' : '')
                  }
                  type="button"
                  data-toggle="dropdown"
                  onClick={() => {
                    this.setState({ showOptions: !this.state.showOptions });
                  }}
                  key="dropdown-multi-select"
                >
                  {!disabled ? (
                    this.renderSingleSelectButtonContent()
                  ) : placeholder ? (
                    <span className="placeholder">{placeholder}</span>
                  ) : null}
                </button>,

                <ul className="dropdown-menu" key="dropdown-menu">
                  {this.props.title && (
                    <div className="dropdown-title">
                      <span>{this.props.title}</span>
                    </div>
                  )}
                  {filter && (
                    <input
                      className={'filter-input'}
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          filterValue: e.target.value,
                        });
                      }}
                    />
                  )}
                  {search && (
                    <div className={'search-bar'}>
                      <i className={'fa fa-search'} />
                      <Input
                        name="search"
                        clearInput={
                          this.props.clearInput || this.state.clearInputValue
                        }
                        value={{
                          inputValue: this.state.search,
                        }}
                        placeholder={I18n.t('persons.search')}
                        change={this.handleSearch}
                      />
                    </div>
                  )}
                  {this.props.cleanOption && (
                    <li
                      onClick={(e) => {
                        this.handleSelection(
                          e,
                          !this.props.emptyOption
                            ? { description: '' }
                            : {
                                description: this.props.emptyOption.description,
                                code: this.props.emptyOption.code,
                              },
                        );
                        this.setState({
                          ...this.state,
                          search: '',
                          clearInputValue: true,
                        });
                        this.props.dropDownClean();
                      }}
                      className="clean-filter"
                    >
                      {this.props.textCleanOption ||
                        I18n.t('dropdown.cleanFilter')}
                    </li>
                  )}
                  {this.filterAndOrder(this.props.options)
                    .filter((op) =>
                      op?.description
                        ?.toLowerCase()
                        .includes(this.state.search.toLowerCase()),
                    )
                    .map((option, i) => (
                      <li
                        className={`${option.subitem ? 'sub-item' : ''} ${
                          option.disabled ? 'disabled' : ''
                        } ${
                          multiSelect && this.optionIsSelected(option)
                            ? 'selected'
                            : ''
                        }`}
                        key={option.code + '-' + i}
                        onClick={(e) =>
                          !option.disabled && this.handleSelection(e, option)
                        }
                      >
                        {translate
                          ? I18n.t(option.description || option.label)
                          : option.description || option.label}
                        {option.menu && <img src={dropdownIcon} alt="" />}
                      </li>
                    ))}
                </ul>,
              ]}
        </div>
        {bottomText?.appears && !disabled && (
          <label className={'bottom-text-label danger'}>
            {bottomText?.message}
          </label>
        )}
      </div>
    );
  }

  renderMultiSelectButtonContent() {
    const { loading, value, placeholder } = this.props;
    let result = [];

    if (value.inputValue?.length > 0)
      result.push(
        <label>
          {value.inputValue.map((value) => value.description).join(', ')}
        </label>,
      );
    else if (placeholder) result.push(<label>{placeholder}</label>);
    else result.push(<label>{I18n.t('dropdown.default')}</label>);
    result.push(<span className="material-symbols-rounded">expand_more</span>);
    return result;
  }

  renderSingleSelectButtonContent() {
    const { loading, value, translate, placeholder } = this.props;
    const { description, label } = this.props.value.inputValue || {};

    return [
      <label key="dropdown-single-select-label">
        {value.inputValue
          ? (description || label) && translate
            ? I18n.t(description || label)
            : description || label || placeholder
          : placeholder || I18n.t('dropdown.default')}
      </label>,
      <span className="material-symbols-rounded">expand_more</span>,
    ];
  }
}

FormDropdown.defaultProps = {
  value: {},
};
FormDropdown.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  change: PropTypes.func,
  addOption: PropTypes.func,
  clearInput: PropTypes.bool,
  value: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.any,
  translate: PropTypes.bool,
  noBorder: PropTypes.bool,
  navbarFont: PropTypes.bool,
  multiSelect: PropTypes.bool,
  cleanOption: PropTypes.bool,
  oneSelect: PropTypes.bool,
  removeDuplicates: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default FormDropdown;
