import React from 'react';
import { Component } from 'react';
import './CampaignDetailScreen.css';
import Button from '../../../components/common/Button';
import Dropdown from '../../../components/common/Dropdown';
import {
  SnackBar,
  successMessage,
  errorMessage,
} from '../../../utils/snackbar';
import { I18n } from 'react-redux-i18n';
import dayjs from 'dayjs';
import Input from '../../../components/common/Input';
import { emailIsValid } from '../../../utils/parser';
import { notify } from 'react-notify-toast';
import DeleteCampaignModal from '../../../components/common/DeleteCamapignModal/DeleteCampaignModal';
import CreditsWidgets from '../../../components/common/CreditsWidgets/CreditsWidgets';
import OptionalField from '../../../components/common/OptionalField/OptionalField';
import Tooltip from '../../../components/common/Tooltip';
import QRCode from 'qrcode.react';
import Loader from '../../../components/common/Loader';
import ReactFileReader from 'react-file-reader';
import { Pagination } from 'react-bootstrap';
import { Tabs, Tab } from 'react-bootstrap';
import DeleteModal from '../../../components/common/ModalAlert/ModalAlert';
import LargeTextTooltip from '../../../components/common/LargeTextTooltip';
import TableMenuItem from '../../../components/common/TableMenuItem/TableMenuItem';
import PlayingStatus from '../../../components/common/PlayingStatus/PlayingStatus';
import IeIndicator from '../../../components/common/IeIndicator/IeIndicator';
import PlayerDetail from '../../../components/common/PlayerDetail/PlayerDetail';

const PENDING_PEOPLE_PAGE_SIZE = 100;

class CampaignDetailScreen extends Component {
  constructor(props) {
    super(props);
    /* selectedCampaign: {
            id: 151
            campaign_type: "DIRECT"
            auto_result_delivery: true
            name: "Cmpaña Directa y Envio Aautomatico"
            positions: []
            start_date: null
            end_date: null
            partner: 1
            code: "DIE-310-IUG"
            max_credits: 500
            created: true
            credits_assigned: 8
            credits_consumed: 2
            pending_people: (6) [{…}, {…}, {…}, {…}, {…}, {…}]
            played_people: (2) [{…}, {…}]
        } */
    this.state = {
      showNotConsumed: false,
      largeText: false,
      showConsumed: false,
      editing: undefined,
      heightCard: 300,
      tabs: 0,
      buttonHeight: 0,
      screenWidth: undefined,
      values: {
        codeEmail: {
          inputValue: '',
          emails: [],
          validator: () =>
            this.state.values.codeEmail.inputValue?.length > 0 &&
            emailIsValid(this.state.values.codeEmail.inputValue),
        },
        editingEmail: { inputValue: '' },
      },
      submit: false,
      code: {},
      massive: false,
      show: false,
      showDeleteCodesModal: false,
      pendingPag: {
        current: 1,
        total: Math.ceil(
          (this.props.selectedCampaign.pending_people?.filter((people) => {
            if (people.status !== 'PROCESSING') return people;
          }).length || 0) / PENDING_PEOPLE_PAGE_SIZE,
        ),
      },
    };
  }

  componentDidMount() {
    setTimeout(
      () => this.setState({ ...this.state, largeText: !this.state.largeText }),
      1000,
    );
  }
  handleInputChange = (key, value) => {
    this.setState({
      ...this.state,
      values: {
        ...this.state.values,
        [key]: {
          ...this.state.values[key],
          inputValue: value,
        },
      },
    });
  };

  handleGenerateCode = (e) => {
    e.preventDefault();
    if (
      (!this.state.massive &&
        this.props.selectedCampaign.played_people?.some(
          (code) => code.email === this.state.values.codeEmail.inputValue,
        )) ||
      this.props.selectedCampaign.pending_people?.some(
        (code) => code.email === this.state.values.codeEmail.inputValue,
      )
    ) {
      this.setState({
        ...this.state,
        values: {
          ...this.state.values,
          codeEmailError: true,
        },
      });
    } else {
      const emails =
        this.state.values.codeEmail.emails?.length > 1
          ? this.state.values.codeEmail.emails
          : this.state.values.codeEmail.inputValue;

      this.props.generateCode(this.props.selectedCampaign.id, emails);
      this.setState({
        ...this.state,
        values: {
          ...this.state.values,
          codeEmailError: false,
          codeEmail: { inputValue: '' },
        },
        submit: true,
        massive: false,
      });
    }
  };

  handleFiles = (files) => {
    const reader = new FileReader();
    let emails = [];
    const scope = this;
    reader.onload = function (e) {
      emails = e.target.result
        .split('\n')
        .slice(1)
        .map((e) => e.trim())
        .filter((e) => e.indexOf('@') > -1)
        .filter((e) => e);
      const avaibleCodes =
        scope.props.selectedCampaign.credits -
        scope.props.selectedCampaign.credits_assigned;
      if (emails?.length > avaibleCodes) {
        notify.show(
          <SnackBar
            icon={'priority'}
            title={I18n.t('creditsHistory.table.error')}
            body={I18n.t('campaigns.error.massiveCodes', {
              validCode: avaibleCodes,
              massiveCount: emails?.length,
            })}
            type="error"
            iconFilled
          />,
          'custom',
          5000,
          errorMessage,
        );
        scope.props.clearUiValue('generateCodeStatus', 'error');
        scope.clearMassive();
      } else {
        const inputValue =
          emails?.length > 2
            ? `${emails[0]}, ${emails[1]} ...`
            : `${emails[0]}, ${emails[1]}`;
        scope.setState({
          massive: true,
          values: {
            codeEmail: { inputValue, emails },
          },
        });
      }
    };
    reader.readAsText(files[0]);
  };

  clearMassive = () => {
    this.setState({
      massive: false,
      values: {
        codeEmail: { inputValue: '', emails: [] },
      },
    });
  };

  downloadTemplate = () => {
    this.props.downloadTemplate();
  };

  clearEdition = () => {
    this.setState({
      ...this.state,
      editing: undefined,
      values: {
        ...this.state.values,
        editingEmail: {
          ...this.state.values.editingEmail,
          inputValue: '',
        },
      },
    });
  };

  handleCampaignDelete = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ ...this.state, deleteModal: true });
  };

  handleSelect = (e) => {
    if (e === I18n.t('creditsHistory.identifier')) {
      this.props.history.push('credits-history');
    }
  };

  handleCopy = (e) => {
    const code =
      this.props.selectedCampaign.code.toUpperCase().length === 12
        ? `${this.props.selectedCampaign.code
            .toUpperCase()
            .substring(0, 4)}-${this.props.selectedCampaign.code
            .toUpperCase()
            .substring(4, 8)}-${this.props.selectedCampaign.code
            .toUpperCase()
            .substring(8, 12)}`
        : this.props.selectedCampaign.code.toUpperCase().length === 9
        ? `${this.props.selectedCampaign.code
            .toUpperCase()
            .substring(0, 3)}-${this.props.selectedCampaign.code
            .toUpperCase()
            .substring(3, 6)}-${this.props.selectedCampaign.code
            .toUpperCase()
            .substring(6, 9)}`
        : '-';
    let success = false;

    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(code);
      success = true;
    } else {
      const textArea = document.createElement('textarea');
      textArea.style.position = 'fixed';
      textArea.value = code;
      textArea.style.opacity = '0';
      textArea.style.height = '0';
      textArea.style.width = '0';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      textArea.remove();
      success = true;
    }

    if (success === true) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('campaigns.code_copy')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
    } else {
      notify.show(
        <SnackBar
          icon={'report'}
          title={I18n.t('common.error')}
          body={I18n.t('campaigns.code_copy')}
          type="error"
        />,
        'custom',
        5000,
        errorMessage,
      );
    }
    this.props.clearUiValue('createStatus', 'success');
  };

  getPlayerStatus(code) {
    let status;

    //condición de estado pendiente
    if (
      code?.status !== 'COMPLETED' &&
      code?.redemption_date === null &&
      !code?.nwm_resultId
    )
      status = 'pending';

    //condición de estado jugando
    if (code?.status === 'PROCESSING' && code?.redemption_date !== null) {
      status = 'playing';
    }

    //condición de estado incosistente
    if (
      code?.status === 'PROCESSING' &&
      code?.nwm_resultId !== null &&
      (code?.redemption_date === null ||
        dayjs(code?.conversion_date) > dayjs(code?.redemption_date))
    )
      status = 'inconsistent';

    return status;
  }

  render() {
    this.checkForNotifications();
    const pendingList = this.props?.selectedCampaign?.pending_people
      ?.filter((people) => {
        if (people.status === 'PENDING' || people.status === 'PROCESSING') {
          return people;
        }
      })
      .sort((a, b) => new Date(b.created) - new Date(a.created));

    return (
      <div className="container-fluid campaign-detail-screen">
        <DeleteModal
          title={I18n.t('common.attention')}
          message={I18n.t('campaigns.deleteOneCodeModalBody', {
            mail: this.state.code.id
              ? pendingList.find((i) => i.id === this.state.code.id)?.email
              : '',
          })}
          textButtonConfirm={I18n.t('common.confirmDelete')}
          show={this.state.show}
          actionConfirm={() => {
            this.props.deleteCode(this.state.code.campaign, this.state.code.id);
            this.setState({ ...this.state, show: false });
          }}
          actionCancel={() =>
            this.setState({ ...this.state, show: false, code: {} })
          }
          onHide={() => this.setState({ ...this.state, show: false, code: {} })}
          deleteCode
        />
        <DeleteModal
          title={I18n.t('common.attention')}
          message={I18n.t('campaigns.deleteAllCodesModalBody')}
          show={this.state.showDeleteCodesModal}
          actionConfirm={() => {
            this.props.deleteCodes(this.props.selectedCampaign.id);
            this.setState({ ...this.state, showDeleteCodesModal: false });
          }}
          actionCancel={() =>
            this.setState({ ...this.state, showDeleteCodesModal: false })
          }
          textButtonConfirm={I18n.t('common.confirmDelete')}
          onHide={() =>
            this.setState({
              ...this.state,
              showDeleteCodesModal: false,
            })
          }
          deleteAll
        />
        <DeleteModal
          title={I18n.t('common.attention')}
          message={I18n.t('campaigns.deleteCampModalBody', {
            campaign: this.props?.selectedCampaign?.name
              ? this.props.selectedCampaign.name
              : '',
          })}
          show={this.state.deleteModal}
          actionConfirm={() => {
            this.props.deleteCampaign(this.props.selectedCampaign.id);
          }}
          actionCancel={() =>
            this.setState({ ...this.state, deleteModal: false })
          }
          onHide={() => this.setState({ ...this.state, deleteModal: false })}
          textButtonConfirm={I18n.t('common.confirmDelete')}
        />

        <div className="row">
          <div className="col-md-4 campaign-main">
            <Tabs onSelect={this.handleSelect} id="campaign-detail-screen-tabs">
              <Tab
                eventKey={I18n.t('campaigns.identifier')}
                title={I18n.t('campaigns.identifier')}
              >
                <div id="detail-campaign" className="campaign-details">
                  <div className="card-header">
                    <div className="back">
                      {this.props.generateCodeStatus.loading ? (
                        <Loader mini />
                      ) : (
                        <Button
                          className={'bg-primary text-white p-0'}
                          iconButton={true}
                          iconClass={'keyboard_arrow_left'}
                          onClick={() => {
                            this.props.cleanSearchValue(false);
                            this.props.history.push('/main/campaigns');
                          }}
                        />
                      )}

                      <LargeTextTooltip
                        id="campaign-name-header-field"
                        idToOverflow="campaign-name-title"
                      />
                      <p
                        id="campaign-name-title"
                        className="campaign-name"
                        data-tooltip-id="campaign-name-header-field"
                        data-tooltip-content={this.props.selectedCampaign.name}
                      >
                        <span id="campaign-name-span" className="campaign-name">
                          {this.props.selectedCampaign.name}
                        </span>
                      </p>
                      <p className="campaign-created">
                        {dayjs(this.props.selectedCampaign.created).format(
                          'DD/MM/YYYY',
                        )}
                      </p>
                    </div>
                    <TableMenuItem>
                      <div className="dropdown-campaign-options">
                        <Dropdown
                          value={{
                            inputValue: {
                              code: '1',
                              label: (
                                <span className="material-symbols-rounded">
                                  more_vert
                                </span>
                              ),
                            },
                          }}
                          className="campaign-detail-dropdown"
                          noBorder
                          options={[
                            {
                              code: 'edit',
                              label: (
                                <Button
                                  className={'bg-primary text-white p-0'}
                                  iconButton={true}
                                  iconFilled={true}
                                  iconClass={'edit'}
                                  text={I18n.t('common.edit')}
                                  onClick={() =>
                                    this.props.history.push(
                                      '/main/edit-campaign',
                                    )
                                  }
                                />
                              ),
                              disabled: false,
                            },
                            {
                              code: 'delete',
                              label: (
                                <Button
                                  className={'bg-warning text-white p-0'}
                                  iconButton={true}
                                  iconFilled={true}
                                  iconClass={'delete'}
                                  text={I18n.t('common.delete')}
                                  disabled={
                                    this.props.selectedCampaign
                                      .credits_consumed > 0
                                  }
                                  onClick={(e) =>
                                    this.handleCampaignDelete(
                                      e,
                                      this.props.selectedCampaign.id,
                                    )
                                  }
                                />
                              ),
                              disabled: false,
                            },
                          ]}
                        />
                      </div>
                    </TableMenuItem>
                  </div>
                  {process.env.REACT_APP_ENABLED_IA_GAME &&
                    (this.props.user_.branch?.account?.ie_active ||
                      this.props.user_.account?.ie_active) && (
                      <IeIndicator
                        ie_active={this.props.selectedCampaign.ie_game_active}
                      />
                    )}
                  <div className="campaign-info row">
                    <div className="codes">
                      <div className="col-md-6 square-widget">
                        <CreditsWidgets
                          material_symbol={'campaign'}
                          title={I18n.t('credits.associated')}
                          label={I18n.t(
                            'credits.associatedCreditsDetailAlternative',
                          )}
                          value={this.props.selectedCampaign.credits}
                          description={I18n.t('credits.thisCampaign')}
                          type={'column-view'}
                          renderStyle="associated"
                        />
                      </div>
                      <div className="col-md-6 square-widget">
                        <CreditsWidgets
                          material_symbol={'how_to_reg'}
                          title={I18n.t('credits.available')}
                          label={I18n.t('credits.assignedCreditsDetail')}
                          value={
                            this.props.selectedCampaign?.credits -
                            this.props.selectedCampaign?.credits_assigned
                          }
                          description={I18n.t('credits.toPeople')}
                          type={'column-view'}
                          renderStyle="campaign-available"
                        />
                      </div>
                      <div className="lower-section col-md-12">
                        <CreditsWidgets
                          material_symbol={'groups'}
                          title={I18n.t('credits.assigned')}
                          label={I18n.t('credits.currentAssigned')}
                          value={this.props.selectedCampaign.credits_assigned}
                          description={I18n.t(
                            'credits.assignedCreditsDescription',
                          )}
                          type={'row-view'}
                          renderStyle="actives"
                        />
                        <CreditsWidgets
                          material_symbol={'sports_esports'}
                          title={I18n.t('credits.consumed')}
                          value={this.props.selectedCampaign.credits_consumed}
                          description={I18n.t('credits.consumedPeople')}
                          type={'row-view'}
                          renderStyle="consumed"
                        />
                      </div>
                    </div>
                    <div className="campaign-code-info col-md-12">
                      <div className="option-section">
                        <p>{I18n.t('campaigns.registrationOptionalFields')}</p>
                        <div className="option-fields">
                          <div className="col option-card position">
                            <OptionalField
                              material_symbol={'group'}
                              title={I18n.t('campaigns.referenceJob')}
                              iconFilled={true}
                              value={
                                this.props.selectedCampaign.position
                                  ? this.props.selectedCampaign?.position.name
                                  : '-'
                              }
                              type={'row-view reference-job'}
                              renderStyle=""
                              disabled={
                                this.props.selectedCampaign.auto_report_delivery
                              }
                            />
                            {this.props.selectedCampaign.fields?.map((f) => {
                              if (f.name === 'field_EAE')
                                return (
                                  <OptionalField
                                    material_symbol={'fact_check'}
                                    title={I18n.t(
                                      'campaigns.eaeFields.registrationData',
                                    )}
                                    iconFilled={true}
                                    value={
                                      f.value
                                        ? I18n.t('campaigns.eaeFields.selected')
                                        : I18n.t(
                                            'campaigns.eaeFields.notSelected',
                                          )
                                    }
                                    type={'row-view reference-job eae-fields'}
                                    renderStyle=""
                                    disabled={
                                      this.props.selectedCampaign
                                        .auto_report_delivery
                                    }
                                  />
                                );
                            })}
                          </div>
                          <div className="option-card-container">
                            <div className="col option-card">
                              <OptionalField
                                material_symbol={'assignment'}
                                title={I18n.t('campaigns.autoDelivery')}
                                value={
                                  this.props.selectedCampaign
                                    .auto_report_delivery
                                    ? I18n.t('common.yes')
                                    : I18n.t('common.no')
                                }
                                type={'column-view auto-report'}
                                renderStyle=""
                                disabled={
                                  this.props.selectedCampaign
                                    .auto_report_delivery
                                }
                                iconFilled
                              />
                            </div>

                            {this.props.selectedCampaign.fields
                              ?.slice(0, 4)
                              .map((field) => {
                                let option_icon = '';
                                let option_title = '';
                                if (field.name === 'phone_number') {
                                  option_icon = 'smartphone';
                                  option_title = I18n.t('campaigns.phone');
                                }
                                if (field.name === 'doc_value') {
                                  option_icon = 'fingerprint';
                                  option_title = I18n.t(
                                    'campaigns.personal_id',
                                  );
                                }
                                if (field.name === 'city') {
                                  option_icon = 'map';
                                  option_title = I18n.t('campaigns.city');
                                }
                                if (field.name === 'address') {
                                  option_icon = 'person_pin_circle';
                                  option_title = I18n.t('campaigns.address');
                                }
                                return (
                                  <div
                                    key={field.option_title}
                                    className="col option-card"
                                  >
                                    <OptionalField
                                      material_symbol={option_icon}
                                      title={option_title}
                                      value={
                                        field.value
                                          ? I18n.t('common.yes')
                                          : I18n.t('common.no')
                                      }
                                      type={'column-view'}
                                      renderStyle=""
                                      disabled={field.value}
                                      iconFilled={true}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey={I18n.t('creditsHistory.identifier')}
                title={I18n.t('creditsHistory.identifier')}
              ></Tab>
            </Tabs>
          </div>
          <div className="col-md-8 campaign-codes-count">
            <div className="row">
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-6 card campaign-codes-not-consumed">
                    <div className="head-container">
                      <p className="not-title">{I18n.t('credits.consumed')}</p>
                      <span className="greyed-quant">
                        {this.props.selectedCampaign.credits_consumed}
                      </span>
                    </div>

                    <ul className="info-list">
                      {this.props.selectedCampaign.played_people
                        .sort(
                          (a, b) => new Date(b.created) - new Date(a.created),
                        )
                        .map((code, index) => {
                          let code_ie_filtered;
                          if (this.props.selectedCampaign.played_people_ie)
                            code_ie_filtered =
                              this.props.selectedCampaign.played_people_ie?.filter(
                                (p) => p.email === code.email,
                              );

                          return this.state.editing !== code.id ? (
                            <li>
                              <PlayerDetail
                                index={index}
                                email={code.email}
                                largeText={this.state.largeText}
                                code={code.code}
                                code_ie={
                                  code_ie_filtered
                                    ? code_ie_filtered[0]?.code
                                    : null
                                }
                              />
                            </li>
                          ) : (
                            <li>
                              <Input
                                name="editingEmail"
                                value={this.state.values.editingEmail}
                                change={this.handleInputChange}
                              />
                              <p>{code.code}</p>
                            </li>
                          );
                        })}
                      {this.props.generateCodeStatus.loading && (
                        <li>
                          <Loader mini />
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="col-md-6 card campaign-codes-not-consumed">
                    <div className="head-container">
                      <p className="not-title">
                        {I18n.t('credits.notConsumed')}
                      </p>
                      <span className="greyed-quant">{pendingList.length}</span>
                    </div>

                    <ul className="info-list">
                      {pendingList
                        .sort(
                          (a, b) => new Date(b.created) - new Date(a.created),
                        )
                        .map((code, index) =>
                          this.state.editing !== code.id ? (
                            <li>
                              <div
                                className="info-container"
                                key={`email-not-consumed-${index}`}
                              >
                                <PlayingStatus
                                  status={this.getPlayerStatus(code)}
                                  redemptionDate={code.redemption_date}
                                />
                                <div className="email-container">
                                  <p
                                    id={`email-not-consumed-${index}`}
                                    data-tooltip-content={code.email}
                                    data-tooltip-id={`email-not-consumed-tooltip-${index}`}
                                  >
                                    {code.email}{' '}
                                  </p>

                                  <Button
                                    className={'text-warning no-border p-0'}
                                    iconButton={true}
                                    iconClass={'delete'}
                                    iconFilled
                                    disabled={
                                      this.getPlayerStatus(code) === 'playing'
                                    }
                                    // TODO: onClick function outside render()
                                    onClick={() => {
                                      this.props.cleanPlayingCodeError();
                                      this.setState({
                                        ...this.state,
                                        show: true,
                                        code: code,
                                      });
                                    }}
                                  />
                                </div>
                                <span>
                                  {code.code.length === 12
                                    ? `${code.code.substring(
                                        0,
                                        4,
                                      )}-${code.code.substring(
                                        4,
                                        8,
                                      )}-${code.code.substring(8, 12)}`
                                    : code.code.length === 9
                                    ? `${code.code.substring(
                                        0,
                                        3,
                                      )}-${code.code.substring(
                                        3,
                                        6,
                                      )}-${code.code.substring(6, 9)}`
                                    : '-'}
                                </span>

                                <LargeTextTooltip
                                  id={`email-not-consumed-tooltip-${index}`}
                                  idToOverflow={`email-not-consumed-${index}`}
                                  key={`email-not-consumed-${this.state.largeText}`}
                                />
                              </div>
                            </li>
                          ) : (
                            <li>
                              <Button
                                className={'text-primary no-border p-0'}
                                iconButton={true}
                                iconClass={'fa fa-check'}
                                // TODO: onClick function outside render()
                                onClick={async () => {
                                  await this.props.deleteCode(
                                    code.nwm_campaignId,
                                    code.id,
                                  );
                                  this.props.generateCode(
                                    this.props.selectedCampaign.id,
                                    this.state.values.editingEmail.inputValue,
                                  );
                                  this.clearEdition();
                                }}
                              />
                              <Input
                                name="editingEmail"
                                value={this.state.values.editingEmail}
                                change={this.handleInputChange}
                              />
                              <p>{code.code}</p>
                              <Button
                                className={'text-warning no-border p-0'}
                                iconButton={true}
                                iconClass={'fa fa-times'}
                                onClick={this.clearEdition}
                              />
                            </li>
                          ),
                        )}
                      {this.props.generateCodeStatus.loading && (
                        <li>
                          <Loader mini />
                        </li>
                      )}
                    </ul>
                    <>
                      {!!this.props.selectedCampaign.pending_people?.length && (
                        <>
                          <Button
                            className="danger delete-button"
                            id="delete-btn"
                            text={I18n.t('common.deleteAll')}
                            onClick={() => {
                              this.props.cleanPlayingCodeError();
                              this.setState({
                                ...this.state,
                                showDeleteCodesModal: true,
                              });
                            }}
                          />
                        </>
                      )}
                    </>
                  </div>
                </div>
              </div>
              <div className="col-md-5 campaign-code">
                <div className="card qr-code-card">
                  <div className="qr-title">
                    <p>{I18n.t('campaigns.campaignCodes')}</p>
                    <Tooltip
                      className="associated-codes"
                      message="campaigns.code_massive"
                      materialIcon={
                        <span className="material-symbols-rounded">
                          contact_support
                        </span>
                      }
                    />
                  </div>
                  <div className="qr-body">
                    <div className="qr-code">
                      <QRCode
                        className="img-responsive"
                        width={150}
                        height={150}
                        value={
                          this.props.selectedCampaign.code.toUpperCase()
                            .length === 12
                            ? `${this.props.selectedCampaign.code
                                .toUpperCase()
                                .substring(
                                  0,
                                  4,
                                )}-${this.props.selectedCampaign.code
                                .toUpperCase()
                                .substring(
                                  4,
                                  8,
                                )}-${this.props.selectedCampaign.code
                                .toUpperCase()
                                .substring(8, 12)}`
                            : this.props.selectedCampaign.code.toUpperCase()
                                .length === 9
                            ? `${this.props.selectedCampaign.code
                                .toUpperCase()
                                .substring(
                                  0,
                                  3,
                                )}-${this.props.selectedCampaign.code
                                .toUpperCase()
                                .substring(
                                  3,
                                  6,
                                )}-${this.props.selectedCampaign.code
                                .toUpperCase()
                                .substring(6, 9)}`
                            : '-'
                        }
                      />
                    </div>
                    <div className="qr-campaign-code">
                      {this.props.selectedCampaign.code.toUpperCase() && (
                        <div className="campaign-code-info qr">
                          <p style={{ textAlign: 'center' }}>
                            {this.props.selectedCampaign.code.toUpperCase()
                              .length === 12
                              ? `${this.props.selectedCampaign.code
                                  .toUpperCase()
                                  .substring(
                                    0,
                                    4,
                                  )}-${this.props.selectedCampaign.code
                                  .toUpperCase()
                                  .substring(
                                    4,
                                    8,
                                  )}-${this.props.selectedCampaign.code
                                  .toUpperCase()
                                  .substring(8, 12)}`
                              : this.props.selectedCampaign.code.toUpperCase()
                                  .length === 9
                              ? `${this.props.selectedCampaign.code
                                  .toUpperCase()
                                  .substring(
                                    0,
                                    3,
                                  )}-${this.props.selectedCampaign.code
                                  .toUpperCase()
                                  .substring(
                                    3,
                                    6,
                                  )}-${this.props.selectedCampaign.code
                                  .toUpperCase()
                                  .substring(6, 9)}`
                              : '-'}
                          </p>
                        </div>
                      )}
                      <Button
                        className={'bg-primary text-white p-0'}
                        iconButton
                        iconFilled
                        iconClass={'content_copy'}
                        onClick={this.handleCopy}
                        text={I18n.t('common.copyCode')}
                      />
                    </div>
                  </div>
                </div>

                <div className="campaign-code-generation card ">
                  <form onSubmit={this.handleGenerateCode}>
                    <div className="form-title">
                      <label className="input-label">
                        {I18n.t('campaigns.generateCodeTitle')}
                      </label>
                      <Tooltip
                        className="associated-codes"
                        message="campaigns.code_create"
                        materialIcon={
                          <span className="material-symbols-rounded">
                            contact_support
                          </span>
                        }
                      />
                    </div>
                    <div className="top-half">
                      <div className="input-section">
                        <Input
                          name={'codeEmail'}
                          value={this.state.values.codeEmail}
                          change={this.handleInputChange}
                          disabled={this.state.massive}
                          placeholder={I18n.t('login.placeholders.email')}
                          required
                          validator={() => this.state.values.codeEmailError}
                          ignoreDirty
                        />
                      </div>
                      <div className="upload-csv">
                        <div className="clear-massive">
                          {this.state.massive && (
                            <Button
                              onClick={() => this.clearMassive()}
                              iconButton={true}
                              className={'primary buttons-csv'}
                              iconClass={'close'}
                            />
                          )}
                        </div>
                        {!this.state.massive && (
                          <ReactFileReader
                            handleFiles={this.handleFiles}
                            fileTypes={'.csv'}
                            disabled={
                              this.props.generateCodeStatus.loading
                                ? true
                                : false
                            }
                          >
                            <Button
                              title={I18n.t('campaigns.massive')}
                              iconButton={true}
                              className={`primary buttons-csv ${
                                this.props.generateCodeStatus.loading
                                  ? 'disabled disabled-button'
                                  : ''
                              }`}
                              iconClass={'upload'}
                              type={'button'}
                            />
                          </ReactFileReader>
                        )}
                        <div className="chart-button">
                          <Button
                            title={I18n.t('campaigns.csv-template')}
                            onClick={() => this.downloadTemplate()}
                            iconButton={true}
                            className={'primary buttons-csv'}
                            iconClass={'add_chart'}
                            type="button"
                          />
                        </div>
                      </div>
                    </div>
                    <Button
                      type="submit"
                      className={'primary generate-button'}
                      disabled={
                        !emailIsValid(this.state.values.codeEmail.inputValue) ||
                        this.props.generateCodeStatus.loading
                      }
                      text={I18n.t('campaigns.generate')}
                      iconButton
                      iconClass={'add'}
                    />
                    {this.state.values.codeEmailError && (
                      <div className="text-warning">
                        {I18n.t('campaigns.generateCodeEmailExists')}
                      </div>
                    )}
                    {this.props.generateCodeStatus.error &&
                      this.state.submit && (
                        <div className={'text-warning'}>
                          {I18n.t('common.error', '')}
                        </div>
                      )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  checkForNotifications() {
    const {
      generateCodeStatus,
      updateCodeStatus,
      deleteCodeStatus,
      deleteCodesStatus,
    } = this.props;

    /** Generate code */
    if (generateCodeStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('campaigns.success.generateCode')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );
      this.props.clearUiValue('createStatus', 'success');
    }

    if (generateCodeStatus.error) {
      notify.show(
        <div className="error-message-detail-campaign">
          <SnackBar
            icon={'report'}
            title={I18n.t('common.attention')}
            body={I18n.t('campaigns.error.generateCode')}
            type="error"
          />
        </div>,
        'custom',
        5000,
        errorMessage,
      );

      this.props.clearUiValue('generateCodeStatus', 'error');
    }

    /** Update code */
    if (updateCodeStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('campaigns.success.updateCode')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );

      this.props.clearUiValue('updateCodeStatus', 'success');
    }

    if (updateCodeStatus.error) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('creditsHistory.table.error')}
          body={I18n.t('campaigns.error.updateCode')}
          type="error"
          iconFilled
        />,
        'custom',
        5000,
        errorMessage,
      );
      this.props.clearUiValue('updateCodeStatus', 'error');
    }

    /** Delete code */
    if (deleteCodeStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('campaigns.success.deleteCode')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );

      this.props.clearUiValue('deleteCodeStatus', 'success');
    }

    if (deleteCodesStatus.success) {
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('common.excellent')}
          body={I18n.t('campaigns.success.deleteCodes')}
          type="success"
          iconFilled
        />,
        'custom',
        5000,
        successMessage,
      );

      this.props.clearUiValue('deleteCodesStatus', 'success');
    }

    if (deleteCodeStatus.error) {
      notify.show(
        <SnackBar
          icon={'report'}
          title={I18n.t('common.attention')}
          body={
            this.props.playingCode
              ? I18n.t('campaigns.error.deletePlayingCodeError')
              : I18n.t('campaigns.error.deleteCode')
          }
          type="error"
        />,
        'custom',
        5000,
        errorMessage,
      );
      this.props.clearUiValue('deleteCodeStatus', 'error');
    }

    if (deleteCodesStatus.error) {
      notify.show(
        <SnackBar
          icon={'report'}
          title={I18n.t('common.attention')}
          body={I18n.t('campaigns.error.deleteCodes')}
          type="error"
        />,
        'custom',
        5000,
        errorMessage,
      );
      this.props.clearUiValue('deleteCodesStatus', 'error');
    }
    if (this.props.campaignDeleteStatus.success) {
      this.props.history.push('campaigns');
    }
    if (this.props.campaignDeleteStatus.error) {
      let message = I18n.t('campaigns.error.delete');
      if (
        this.props.campaignDeleteStatus.errorDetailsDeleting ===
        'deleteErrorByUserPlaying'
      ) {
        message = I18n.t(
          'campaigns.error.' +
            this.props.campaignDeleteStatus.errorDetailsDeleting,
        );
      }
      notify.show(
        <SnackBar
          icon={'priority'}
          title={I18n.t('creditsHistory.table.error')}
          body={message}
          type="error"
          iconFilled
        />,
        'custom',
        5000,
        errorMessage,
      );
      this.props.clearUiValue('campaignDeleteStatus', 'error');
    }
  }
}

export default CampaignDetailScreen;
